import { css, cx } from "@emotion/css";
import { Tab as MuiTab, Tabs as MuiTabs, Tooltip } from "@material-ui/core";
import { ExclamationTriangleIcon } from "@octopusdeploy/design-system-icons";
import { borderRadius, themeTokens, space, text } from "@octopusdeploy/design-system-tokens";
import type { ReactElement, ReactNode, ChangeEvent } from "react";
import React from "react";
import { useIsLegacySmallScreen } from "../../hooks";
import type { BreadcrumbItem } from "../Breadcrumbs";
import { Callout, type CalloutType } from "../Callout/Callout";
import type { SimpleMenuItem } from "../MenuItems/SimpleMenuItems";
import type { PageAction, PrimaryPageAction } from "../PageActions/PageActions";
import { PageHeaderSecondary } from "../PageHeaderSecondary";
import { LinearProgress } from "../Progress/LinearProgress";
import { AdvancedFilters } from "./AdvancedFilters";
import { ErrorPanel, type ErrorInfo } from "./ErrorPanel";
import { Filters, FiltersSummary } from "./Filters";
import { useAdvancedFiltersVisibility } from "./useAdvancedFiltersVisibility";
interface Level2PageContentBaseProps {
    /**
     * Defines the elements used in the page header, ensuring a consistent and structured layout.
     * The `title` is required, while other elements such as chips, breadcrumbs, and actions are optional.
     *
     * - **title**: `string` *(required)*
     * The main title of the page. This is a required field and serves as the primary header text.
     * - **titleComplementaryText**: `string` *(required)*
     * Text which can provide additional context to both the title and the chip e.g. [Title] Exclusive to [Chip] *(optional)*.
     * - **logo**: `ReactElement` *(optional)*
     * A custom logo to display as part of the title
     * - **chip**: `ReactElement` *(optional)*
     * A custom chip component to display alongside the title, often used for status or categorization. Refer to the Chip component.
     * - **breadcrumbs**: `BreadcrumbItem[]` *(optional)*
     * An array of breadcrumb items for hierarchical navigation. Refer to the the Breadcumbs component.
     * - **showBreadcrumbBackIcon**: `boolean` *(optional)*
     * to show a back icon next to the breadcrumb
     * - **primaryAction**: `PrimaryPageAction` *(optional)*
     * Defines the primary action for the page, such as a button for a key task.
     * Example: `{ label: "Save", onClick: () => handleSave() }`.
     * - **pageActions**: `PageAction[]` *(optional)*
     * An array of secondary actions displayed on the page, typically as buttons.
     * Example: `{ label: "Edit", type: "Button", buttonType: "secondary", onClick: () => handleEdit() }`.
     * - **overflowActions**: `SimpleMenuItem[]` *(optional)*
     * Actions displayed in an overflow menu for additional functionality. Refer to the the Simple Menu Items component.
     */
    header: {
        title: string;
        titleComplementaryText?: string;
        logo?: ReactElement;
        chip?: ReactElement;
        contextSelector?: ReactElement;
        breadcrumbs?: BreadcrumbItem[];
        showBreadcrumbBackIcon?: boolean;
        primaryAction?: PrimaryPageAction;
        pageActions?: PageAction[];
        overflowActions?: SimpleMenuItem[];
    };
    busy: boolean;
    /**
     * A custom description to be rendered
     */
    description?: ReactNode;
    /**
     * The `Callout` component displays important information or alerts to the user.
     * It supports two configurations: a custom callout with a React element as content,
     * or a standard callout with a title, content, and optional close functionality.
     *
     * **Custom Callout**
     * - **type**: `"custom"`
     * Specifies the callout type as "custom".
     * - **content**: `React.ReactElement`
     * A custom React element to render inside the callout.
     *
     * **Standard Callout**
     * - **type**: `"information", "success", "warning", "danger", "new-feature", "generic"`
     * The type of the callout, defining its style and purpose.
     * - **title**: `React.ReactNode` *(optional)*
     * The optional title of the callout.
     * - **content**: `React.ReactNode`
     * The main content or message of the callout.
     * - **onClose**: `() => void` *(optional)*
     * A callback function triggered when the callout is closed.
     */
    callout?: Level2PageContentCalloutType;
    errors?: ErrorInfo[];
    /**
     * Specifies whether the page content should take up the entire width of the page *(optional)*
     */
    fullWidth?: boolean;
}
export type Level2PageContentCalloutType = {
    type: "custom";
    content: React.ReactElement;
} | {
    title?: ReactNode;
    content: ReactNode;
    type: CalloutType;
    onClose?: () => void;
};
interface ContentProps {
    /**
     * Defines the structure for filter inputs, summaries, and advanced filtering options in a page layout.
     * - **inputs**: `React.ReactNode[]` *(required)*
     * An array of input elements for filters. These can include dropdowns, text inputs, or custom filter components.
     *
     * - **filtersSummary**: `React.ReactNode` *(optional)*
     * A summary or overview of the applied filters, typically displayed above or near the filter inputs.
     *
     * - **advancedFilters**: `object` *(optional)*
     * Advanced filtering options for more complex use cases:
     * -- **content**: `React.ReactNode`
     * Custom content for the advanced filters section, such as additional inputs or filter groups.
     * -- **onResetFilter**: `() => void`
     * A callback function to reset the advanced filters to their default state.
     * -- **hasUserSelectedValues**: `boolean`
     * Indicates whether the user has applied any values in the advanced filters.
     */
    filters?: {
        inputs: ReactNode[];
        filtersSummary?: ReactNode;
        advancedFilters?: {
            content: ReactNode;
            onResetFilter: () => void;
            hasUserSelectedValues: boolean;
        };
    };
    /**
     * Defines the UI and placement options for pagination controls within a page layout.
     *
     * - **ui**: `React.ReactNode` *(required)*
     * The React node representing the pagination UI, such as a component for navigating through pages.
     * - **placement**: `"top" | "bottom" | "topAndBottom"` *(required)*
     * Specifies where the pagination controls are displayed on the page:
     * - `"top"`: Displays pagination controls at the top of the page.
     * - `"bottom"`: Displays pagination controls at the bottom of the page.
     * - `"topAndBottom"`: Displays pagination controls at both the top and bottom of the page.
     */
    pagination?: {
        ui: ReactNode;
        placement: "top" | "bottom" | "topAndBottom";
    };
    /**
     * Defines a custom sidebar that will be rendered as part of the page content
     * @deprecated Avoid using sidebars for new pages; Consult your designer or Frontend Foundations Team for alternative solutions.
     */
    sidebar?: ReactNode;
}
export type Level2PageContentPropsWithoutTabs = Level2PageContentBaseProps & ContentProps & {
    /**
     * The children to render as part of the page content. *(required if no tabs)*
     */
    children: ReactNode;
    legacyTabs?: ReactNode;
};
export type Level2PageContentPropsWithTabs<TabValue extends string> = Level2PageContentBaseProps & {
    /**
     * Defines the tabs along with the content to show for each tab option. A level 2
     * page can only have top level tabs or content, but not both.
     * - **label**: `string` *(required)*
     * The label to use when rendering the tab
     * - **value**: `string` *(required)*
     * The value that uniquely identifies the tab
     * - **content**: `ReactNode` *(required)*
     * The content to display when the tab is selected
     * - **warning**: `string` *(optional)*
     * A warning which applies to the tab
     */
    tabs: Tab<TabValue>[];
    /**
     * A callback used to set the currently selected tab
     */
    onTabChanged: (value: TabValue) => void;
    /**
     * The current selected tab
     */
    currentTab: TabValue;
};
export interface Tab<TabValue extends string> extends ContentProps {
    value: TabValue;
    label: string;
    content: ReactNode;
    warning?: string;
}
export type Level2PageContentProps<TabValue extends string = never> = Level2PageContentPropsWithTabs<TabValue> | Level2PageContentPropsWithoutTabs;
export function Level2PageContent<TabValue extends string>({ header, busy = false, description, callout, errors, fullWidth, ...contentProps }: Level2PageContentProps<TabValue>) {
    const hasTabs = "tabs" in contentProps;
    const currentTab = hasTabs ? contentProps.tabs.find((tab) => tab.value === contentProps.currentTab) : undefined;
    const content = hasTabs ? currentTab?.content : contentProps.children;
    const filters = hasTabs ? currentTab?.filters : contentProps.filters;
    const pagination = hasTabs ? currentTab?.pagination : contentProps.pagination;
    const sidebar = hasTabs ? currentTab?.sidebar : contentProps.sidebar;
    const legacyTabs = hasTabs ? undefined : contentProps.legacyTabs;
    const isSmallScreen = useIsLegacySmallScreen();
    const [isAdvancedFiltersVisible, setIsAdvancedFiltersVisible] = useAdvancedFiltersVisibility(filters?.advancedFilters);
    const paginationAtBottom = pagination && pagination.placement !== "top";
    const paginationAtTop = pagination && pagination.placement !== "bottom";
    const hasAdvancedFilters = filters?.advancedFilters !== undefined;
    const hasFilters = (filters?.inputs && filters.inputs.length > 0) || hasAdvancedFilters;
    const hasFilterAndPaginationSection = hasFilters || filters?.filtersSummary !== undefined || paginationAtTop;
    const constrainedWidthStyles = fullWidth ? undefined : pageContentStyles.containerMaxWidth;
    const handleTabChange = hasTabs
        ? (event: ChangeEvent<{}>, value: string) => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            contentProps.onTabChanged(value as TabValue);
        }
        : undefined;
    return (<div className={cx(pageContentStyles.container, constrainedWidthStyles)}>
            <LinearProgress variant={"indeterminate"} show={busy}/>
            <PageHeaderSecondary breadcrumbsItems={header.breadcrumbs} showBreadcrumbBackIcon={header.showBreadcrumbBackIcon} title={header.title} titleComplementaryText={header.titleComplementaryText} titleChip={header.chip} titleAccessory={header.contextSelector} titleStatusIcon={header.logo} primaryAction={header.primaryAction} pageActions={header.pageActions} overflowActions={header.overflowActions} isStickied/>
            {description}
            {errors?.map((error, index) => (<ErrorPanel key={index} error={error}/>))}
            {callout &&
            (callout.type === "custom" ? (callout.content) : (<Callout type={callout.type} title={callout.title} hideTitle={!callout.title} onClose={callout.onClose} canClose={!!callout.onClose}>
                        {callout.content}
                    </Callout>))}
            {hasFilterAndPaginationSection && (<div className={pageContentStyles.filtersAndPaginationSection}>
                    <div className={pageContentStyles.filtersAndPaginationRow}>
                        {hasFilters && <Filters inputs={filters.inputs} showAdvancedFiltersToggle={hasAdvancedFilters} isAdvancedFiltersVisible={isAdvancedFiltersVisible} onAdvancedFiltersVisibilityChanged={setIsAdvancedFiltersVisible}/>}
                        {paginationAtTop && pagination.ui}
                    </div>
                    {filters?.filtersSummary && <FiltersSummary summary={filters.filtersSummary}/>}
                </div>)}
            <div className={cx(pageContentStyles.sectionsWithSidebars, {
            [pageContentStyles.sectionsWithSidebarsVertical]: isSmallScreen,
            [pageContentStyles.sectionsWithSidebarsTopBorder]: sidebar !== undefined,
        })}>
                {filters?.advancedFilters && isAdvancedFiltersVisible && (<AdvancedFilters content={filters.advancedFilters.content} onResetFilter={filters.advancedFilters.onResetFilter} isResetEnabled={filters.advancedFilters.hasUserSelectedValues} parentOrientation={isSmallScreen ? "column" : "row"}/>)}
                <div className={pageContentStyles.contentBox}>
                    {hasTabs && contentProps.tabs.length > 0 ? (<MuiTabs classes={{ indicator: pageContentStyles.tabIndicator }} className={pageContentStyles.tabs} value={contentProps.currentTab} onChange={handleTabChange} variant="scrollable" scrollButtons="off">
                            {contentProps.tabs.map((tab) => (<MuiTab key={tab.value} className={pageContentStyles.tab} value={tab.value} label={tab.warning ? <TabLabelWithWarning label={tab.label} warning={tab.warning}/> : tab.label}/>))}
                        </MuiTabs>) : (legacyTabs)}
                    {content}
                </div>
                {sidebar && <Sidebar content={sidebar} parentOrientation={isSmallScreen ? "column" : "row"}/>}
            </div>
            {paginationAtBottom && <div className={pageContentStyles.footerPaginationSection}>{pagination.ui}</div>}
        </div>);
}
const pageContentStyles = {
    container: css({
        display: "flex",
        flexDirection: "column",
        borderRadius: borderRadius.small,
        border: `1px solid ${themeTokens.color.border.primary}`,
        minHeight: 640,
        marginLeft: "auto",
        marginRight: "auto",
        font: text.regular.default.medium,
    }),
    containerMaxWidth: css({
        maxWidth: 1280,
    }),
    subHeaderContainer: css({}),
    filtersAndPaginationSection: css({
        padding: `${space[8]} ${space[16]}`,
        gap: space[8],
    }),
    filtersAndPaginationRow: css({
        display: "flex",
        justifyContent: "space-between",
    }),
    sectionsWithSidebars: css({
        display: "flex",
        flex: 1,
        color: themeTokens.color.text.primary,
    }),
    sectionsWithSidebarsTopBorder: css({
        borderTop: `1px solid ${themeTokens.color.border.primary}`,
    }),
    sectionsWithSidebarsVertical: css({
        flexDirection: "column",
    }),
    contentBox: css({
        flex: 1,
        minWidth: 0,
    }),
    tabs: css({
        color: themeTokens.color.text.secondary,
    }),
    tab: css({
        "&.MuiTab-root": {
            minWidth: "7.23rem",
            textTransform: "none",
            font: text.regular.bold.medium,
            "&.Mui-selected": {
                color: themeTokens.color.text.selected,
            },
        },
    }),
    tabIndicator: css({
        "&.MuiTabs-indicator": {
            backgroundColor: themeTokens.color.text.selected,
        },
    }),
    footerPaginationSection: css({
        display: "flex",
        justifyContent: "flex-end",
        padding: space[16],
    }),
};
interface SidebarProps {
    content: ReactNode;
    parentOrientation: "row" | "column";
}
function Sidebar({ content, parentOrientation }: SidebarProps) {
    return (<div className={cx(sidebarStyles.root, {
            [sidebarStyles.inRow]: parentOrientation === "row",
            [sidebarStyles.inColumn]: parentOrientation === "column",
        })}>
            {content}
        </div>);
}
const sidebarStyles = {
    root: css({
        padding: `0 ${space[16]}`,
    }),
    inRow: css({
        width: "17.5rem",
        borderLeft: `1px solid ${themeTokens.color.border.primary}`,
    }),
    inColumn: css({
        borderTop: `1px solid ${themeTokens.color.border.primary}`,
    }),
};
function TabLabelWithWarning({ label, warning }: {
    label: string;
    warning: string;
}) {
    return (<Tooltip title={warning}>
            <div className={tabLabelWithWarningStyles.root}>
                {label}
                <ExclamationTriangleIcon size={24} color={themeTokens.color.icon.attention}/>
            </div>
        </Tooltip>);
}
const tabLabelWithWarningStyles = {
    root: css({
        display: "flex",
        gap: space[4],
        alignItems: "center",
    }),
};
