import type { ActionTemplateParameterResource } from "./actionTemplateParameterResource";
import type { ICommitCommand } from "./commitCommand";
import type { IProcessResource, ModifyProcessCommand } from "./deploymentProcessResource";
import type { IconResource } from "./iconResource";
import type { IId } from "./resource";
export interface BlueprintResource extends IProcessResource, IId {
    Name: string;
    ProjectId: string;
    GitRef: string;
    Slug: string;
    Description?: string;
    Icon?: IconResource;
    Parameters: ActionTemplateParameterResource[];
}
export interface ProcessTemplateListSummaryResource {
    Name: string;
    Slug: string;
    Description?: string;
    Icon?: IconResource;
}
export type ModifyProcessTemplateCommand = BlueprintResource & ICommitCommand;
export function isModifyProcessTemplateCommand(command: ModifyProcessCommand): command is ModifyProcessTemplateCommand {
    return "Parameters" in command;
}
export interface DeleteProcessTemplateCommand extends ICommitCommand {
    Id: string;
}
export interface NewProcessTemplate extends ICommitCommand {
    Name: string;
    Description: string;
}
export interface CreateProcessTemplateVersionBffCommand {
    ProcessTemplateId: string;
    Version: string;
    IsPreRelease: boolean;
}
export interface ProcessTemplateSummary extends IId {
    Name: string;
    ProjectId: string;
    GitRef: string;
    Slug: string;
    Description?: string;
    Icon?: IconResource;
    Version?: string;
    PublishedDate?: string;
}
export interface ProcessTemplateVersionResource {
    ProcessTemplateSlug: string;
    GitCommit: string;
    GitRef: string;
    Version: string;
    PublishedDate: string;
    IsPreRelease: boolean;
}
export interface GetSharedSpacesToProcessTemplateBySlugBffResponse {
    SharedToAllSpaces: boolean;
    SpaceIds: string[];
}
export interface ShareTemplateToManySpacesBffCommand {
    ProcessTemplateId: string;
    ShareToAllSpaces: boolean;
    SpaceIds: string[];
}
export interface ShareTemplateToManySpacesBffResponse {
    SharedToAllSpaces: boolean;
    SharedSpaceIds: string[];
    UnsharedSpaceIds: string[];
}
