import { type KubernetesStepStatus, type KubernetesObjectStatus, ActivityStatus } from "@octopusdeploy/octopus-server-client";
import React, { useMemo, useState } from "react";
import { getStepActivity } from "~/areas/tasks/components/Task/K8sStatus/getStepActivity";
import type { KubernetesDetailsBaseProps, KubernetesDetailsFilter, KubernetesDetailsStepView } from "~/areas/tasks/components/Task/Kubernetes/KubernetesDetailsLayout";
import { KubernetesObjectDrawer } from "~/areas/tasks/components/Task/Kubernetes/KubernetesObjectDrawer";
import { KubernetesStepExpander } from "~/areas/tasks/components/Task/Kubernetes/KubernetesStepExpander";
import { ExpansionButtons } from "~/components/form/index";
export const KubernetesStepsLayoutContainerKey = "KubernetesStepsLayout";
type KubernetesStepsLayoutProps = KubernetesDetailsBaseProps & {
    activeView: KubernetesDetailsStepView;
    filter: KubernetesDetailsFilter;
};
export const KubernetesStepsLayout = (props: KubernetesStepsLayoutProps) => {
    const [objectStatusInDrawer, setObjectStatusInDrawer] = useState<KubernetesObjectStatus | undefined>(undefined);
    const stepStatusMap = useMemo(() => {
        const lookup: Record<string, KubernetesStepStatus | undefined> = {};
        for (const step of props.steps) {
            lookup[step.stepName] = props.resourceStatus.KubernetesStepsStatus.find((v) => v.StepName === step.stepName);
        }
        return lookup;
    }, [props.resourceStatus, props.steps]);
    return (<>
            <ExpansionButtons containerKey={KubernetesStepsLayoutContainerKey} expandAllOnMount={true}/>
            {props.steps.map(({ stepName, isResourceStatusEnabled, isAtomicHelmStep }) => {
            const activity = getStepActivity(stepName, props.activityLogs) ?? { Name: stepName, Started: "", Ended: "", Status: ActivityStatus.Pending };
            return (<KubernetesStepExpander key={stepName} stepName={stepName} stepActivity={activity} stepResourceStatus={stepStatusMap[stepName]} isAtomicHelmStep={isAtomicHelmStep} isResourceStatusEnabled={isResourceStatusEnabled} showObjectDrawer={setObjectStatusInDrawer} {...props}/>);
        })}
            <KubernetesObjectDrawer onClose={() => setObjectStatusInDrawer(undefined)} objectStatus={objectStatusInDrawer}/>
        </>);
};
