/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css, cx } from "@emotion/css";
import { Level1PageLayout, Level2PageLayout, type PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { SummaryResource } from "@octopusdeploy/octopus-server-client";
import { EndpointsHelper } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type * as H from "history";
import * as React from "react";
import type { FilterSection } from "~/components/AdvancedFilterLayout";
import { AdvancedFilterCheckbox } from "~/components/AdvancedFilterLayout";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout/AdvancedFilterLayout";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { FullWidthPageLayout } from "~/components/FullWidthPageLayout/FullWidthPageLayout";
import { MachineModelHealthStatusMultiSelect } from "~/components/MultiSelect/MachineModelHealthStatusMultiSelect";
import { LegacyPaperLayout } from "~/components/PaperLayout/LegacyPaperLayout";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import { convertQueryToQueryString } from "~/components/QueryStringFilters/QueryStringFilters";
import MachineHealthStatusHelper from "~/utils/MachineHealthStatusHelper";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import ConfirmTentacleUpgradePanel from "./ConfirmTentacleUpgradePanel";
import type { BaseInfrastructureFilter } from "./MachineFilter";
export interface MachinesLayoutProps<Filter, Query> {
    title: string;
    itemDescriptions: string;
    hasMachines: boolean;
    defaultFilter: Filter;
    initialFilter: Filter;
    filterToQuery: (filter: Filter) => Query;
    location: H.Location;
    history: H.History;
    showV2Page: boolean;
}
export interface MachinesLayoutState<Filter> extends DataBaseComponentState {
    currentPageIndex: number; // This has a custom endpoint, so we manage our own paging implementation in List/onLoadMore.
    expanded: boolean; // Need to know if we're currently expanded so we can choose to reload when the filter changes or not.
    healthStatusFilters: string[];
    isDisabledFilter: boolean | undefined;
    filter: Filter;
    queryFilter: Filter;
    redirectToTasks: boolean;
}
export abstract class BaseMachinesLayout<Props extends MachinesLayoutProps<Filter, Query>, State extends MachinesLayoutState<Filter>, Filter extends BaseInfrastructureFilter, Query extends IQuery> extends DataBaseComponent<Props, State> {
    protected machineHealthStatuses = MachineHealthStatusHelper.getMachineModelHealthStatusResources();
    protected communicationStyles = EndpointsHelper.getCommunicationStyleResources();
    protected requestRaceConditioner = new RequestRaceConditioner();
    protected commonInitialState = {
        redirectToTasks: false,
        currentPageIndex: 0,
        expanded: true,
        healthStatusFilters: [],
        isDisabledFilter: undefined,
        isSearching: false,
    };
    constructor(props: Props) {
        super(props);
    }
    render() {
        if (this.state.redirectToTasks) {
            return <InternalRedirect to={links.tasksPage.generateUrl()} push={true}/>;
        }
        const searchHintText = "Search " + this.props.itemDescriptions + "...";
        const summaries = this.getSummaries();
        const tentacleUpgradesRequiredWarning = summaries && summaries.TentacleUpgradesRequired && (<ConfirmTentacleUpgradePanel doBusyTask={this.doBusyTask} onTentacleUpgradeComplete={() => {
                this.setState({ redirectToTasks: true });
            }}/>);
        const PageLayoutComponent = this.props.showV2Page ? Level1PageLayout : Level2PageLayout;
        const PageContentComponent = this.props.showV2Page ? FullWidthPageLayout : LegacyPaperLayout;
        const pageContentProps = this.props.showV2Page ? { areaTitle: this.props.title } : { title: this.props.title };
        return (<PageLayoutComponent>
                <PageContentComponent {...pageContentProps} busy={this.state.busy} errors={this.errors} primaryAction={this.props.hasMachines ? this.getPrimaryPageAction() : undefined}>
                    <div className={cx({ [machinesDashboardStyles]: this.props.showV2Page })}>
                        {summaries && this.props.hasMachines && this.renderPageSummary()}
                        {summaries && !this.props.hasMachines && this.renderOnboarding()}
                        {tentacleUpgradesRequiredWarning}
                        {this.props.hasMachines && (<AdvancedFilterLayout<Filter> filterSections={this.getFilterSections()} filter={this.state.filter} queryFilter={this.state.queryFilter} defaultFilter={this.props.defaultFilter} // Base doesn't decide which kind of filter is passed down
             initiallyShowFilter={this.isFiltering()} additionalHeaderFilters={this.getNameFilter(searchHintText)} onFilterReset={(filter: Filter) => {
                    this.setState({ filter }, () => {
                        this.onFilterChange();
                        const location = { ...(this.props.history as H.History), search: "" };
                        this.props.history.replace(location);
                    });
                }} renderContent={() => this.renderMachinesExpander()}/>)}
                    </div>
                </PageContentComponent>
            </PageLayoutComponent>);
    }
    protected abstract getSummaries(): SummaryResource;
    protected abstract isFiltering(): boolean;
    protected abstract getPrimaryPageAction(): PrimaryPageAction;
    protected abstract renderOnboarding(): JSX.Element;
    protected abstract renderMachinesExpander(): React.ReactNode;
    protected abstract getNameFilter(searchHintText: string): JSX.Element[];
    protected renderPageSummary(): JSX.Element {
        return <React.Fragment />;
    }
    protected getFilterSections() {
        const filters: React.ReactNode[] = [
            <AdvancedFilterCheckbox key="filterDisabled" label="Disabled only" value={this.state.filter.isDisabled === true} onChange={(x) => {
                    this.setFilterState({ isDisabled: x ? x : undefined }, () => {
                        this.onFilterChange();
                    });
                }}/>,
        ];
        filters.push(this.extraFilters());
        filters.push(<MachineModelHealthStatusMultiSelect accessibleName="healthStatusFilter" key="filterStatus" items={this.machineHealthStatuses} value={this.state.filter.healthStatuses} onChange={(x) => {
                this.setFilterState({ healthStatuses: x }, () => {
                    this.onFilterChange();
                });
            }}/>);
        const filterSections: FilterSection[] = [
            {
                render: <div>{filters}</div>,
            },
        ];
        return filterSections;
    }
    protected extraFilters(): React.ReactNode[] {
        return [];
    }
    protected setFilterState<K extends keyof Filter>(state: Pick<Filter, K>, callback?: () => void) {
        this.setState((prev) => ({
            filter: { ...(prev.filter as object), ...(state as object) },
        }), callback);
    }
    protected onFilterChange() {
        const queryString = convertQueryToQueryString(this.props.filterToQuery(this.state.filter));
        if (this.props.location.search !== queryString) {
            const location = { ...this.props.history, search: queryString };
            this.props.history.replace(location);
        }
    }
    static displayName = "BaseMachinesLayout";
}
const machinesDashboardStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space[24],
    padding: `0 ${space["32"]} ${space["32"]}`,
});
