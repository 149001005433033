import type { Client, RequestContext } from "../client";
import type { GetKubernetesResourceManifestByIdBffResponse, GetKubernetesResourceManifestForDeploymentResponse } from "../resources/kubernetesResourceManifestResource";
import type { GetKubernetesResourceManifestSummariesResponse } from "../resources/kubernetesResourceManifestSummaryResource";
export class KubernetesManifestRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    getKubernetesManifest(manifestId: string) {
        return this.client.get<GetKubernetesResourceManifestByIdBffResponse>(this.requestContext, "~/bff/spaces/{spaceId}/kubernetesmanifests/{id}", {
            id: manifestId,
        });
    }
    getKubernetesManifests(deploymentId: string) {
        return this.client.get<GetKubernetesResourceManifestForDeploymentResponse>(this.requestContext, "~/bff/spaces/{spaceId}/kubernetesmanifests?deploymentId={deploymentId}", {
            deploymentId,
        });
    }
    getKubernetesManifestSummaries(deploymentId: string) {
        return this.client.get<GetKubernetesResourceManifestSummariesResponse>(this.requestContext, "~/bff/spaces/{spaceId}/kubernetesmanifestsummaries?deploymentId={deploymentId}", {
            deploymentId,
        });
    }
}
