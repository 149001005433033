import { css } from "@emotion/css";
import { ExclamationTriangleIcon } from "@octopusdeploy/design-system-icons";
import { fontSize, lineHeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { Permission, type DeploymentTargetResource, type EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import React, { useEffect, useState, useCallback } from "react";
import { repository } from "~/clientInstance";
import Chip from "~/components/Chips/Chip";
import { IconStyledForChip } from "~/components/Chips/IconStyledForChips";
import { CloudTargetDiscoveryContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import { MachineIcon } from "~/primitiveComponents/dataDisplay/Icon";
import { ConnectedDeploymentTargetsPopover } from "./ConnectedDeploymentTargetsPopover";
interface ConnectedDeploymentTargetsStatusProps {
    targetTags: string[];
    isCloudTargetDiscoveryEnabled: boolean;
    projectId: string;
    clearWarnings: () => void;
}
export const canShowConnectedDeploymentTargetsStatus = () => {
    return isAllowed({ permission: Permission.MachineView }) && isAllowed({ permission: Permission.EnvironmentView, environment: "*" });
};
export const ConnectedDeploymentTargetsStatus = (props: ConnectedDeploymentTargetsStatusProps) => {
    const MAX_DEPLOYMENT_TARGETS_TO_DISPLAY = 4;
    const [deploymentTargets, setDeploymentTargets] = useState<DeploymentTargetResource[] | undefined>(undefined);
    const [environments, setEnvironments] = useState<EnvironmentResource[] | undefined>(undefined);
    const [deploymentTargetsTotal, setDeploymentTargetsTotal] = useState<number | undefined>(undefined);
    const [individualTagTargets, setIndividualTagTargets] = useState<Record<string, DeploymentTargetResource[]>>({});
    const isImprovedTargetFeedbackFeatureEnabled = useOctopusFeatureToggle("improved-target-feedback", false);
    const showConnectedDeploymentTargetsStatus = isImprovedTargetFeedbackFeatureEnabled && canShowConnectedDeploymentTargetsStatus();
    const getDeploymentTargets = useCallback(async () => {
        if (props.targetTags.length > 0) {
            const individualResponses = await Promise.all(props.targetTags.map(async (tag) => {
                const response = await repository.Machines.list({ roles: tag, take: MAX_DEPLOYMENT_TARGETS_TO_DISPLAY });
                return { tag, targets: response.Items, totalResults: response.TotalResults };
            }));
            const tagTargets: Record<string, DeploymentTargetResource[]> = {};
            individualResponses.forEach(({ tag, targets }) => {
                tagTargets[tag] = targets;
            });
            setIndividualTagTargets(tagTargets);
            setDeploymentTargetsTotal(individualResponses.reduce((total, response) => total + response.totalResults, 0));
            const deploymentTargets = individualResponses.flatMap((response) => response.targets).slice(0, MAX_DEPLOYMENT_TARGETS_TO_DISPLAY);
            const environmentIds = new Set<string>();
            deploymentTargets.forEach((target) => {
                target.EnvironmentIds.forEach((id) => environmentIds.add(id));
            });
            const environments = await repository.Environments.all({ ids: Array.from(environmentIds) });
            environments.sort((a, b) => a.SortOrder - b.SortOrder);
            setDeploymentTargets(deploymentTargets);
            setEnvironments(environments);
        }
        else {
            setDeploymentTargetsTotal(undefined);
            setDeploymentTargets(undefined);
            setEnvironments(undefined);
            setIndividualTagTargets({});
        }
    }, [props.targetTags]);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            getDeploymentTargets();
        }, 300);
        return () => clearTimeout(timeoutId);
    }, [getDeploymentTargets]);
    if (!showConnectedDeploymentTargetsStatus || (!props.isCloudTargetDiscoveryEnabled && deploymentTargetsTotal === undefined)) {
        return <></>;
    }
    const unassignedTags = props.targetTags.filter((tag) => !individualTagTargets[tag] || individualTagTargets[tag].length === 0);
    return (<div className={containerStyles.root}>
            {deploymentTargets !== undefined && environments !== undefined && deploymentTargetsTotal !== undefined && !props.isCloudTargetDiscoveryEnabled && (<div className={containerStyles.connectedDeploymentTargets}>
                    {unassignedTags.length > 0 && <ExclamationTriangleIcon size={24} color={themeTokens.color.icon.attention}/>}
                    <div className={statusTextStyles}>Can deploy to</div>
                    <ConnectedDeploymentTargetsPopover targetTags={props.targetTags} triggerElement={<Chip noTooltip={true} noMargin={true} icon={<IconStyledForChip iconComponent={MachineIcon}/>}>
                                {deploymentTargetsTotal} {deploymentTargetsTotal === 1 ? "deployment target" : "deployment targets"}
                            </Chip>} deploymentTargetsTotal={deploymentTargetsTotal} deploymentTargets={deploymentTargets} environments={environments} projectId={props.projectId} clearWarnings={props.clearWarnings} unassignedTags={unassignedTags}/>
                </div>)}
            {props.isCloudTargetDiscoveryEnabled && (<div className={containerStyles.cloudTargetDiscoveryEnabled}>
                    <div className={statusTextStyles}>Cloud target discovery enabled</div>
                    <div className={contextualHelpContainerStyles}>
                        <CloudTargetDiscoveryContextualHelp />
                    </div>
                </div>)}
        </div>);
};
const ICON_WIDTH = 50;
const ICON_MARGIN_RIGHT = 4;
const containerStyles = {
    root: css({
        marginRight: space[16],
        marginLeft: space[16],
        marginBottom: "22.5px",
        paddingLeft: `${ICON_WIDTH + ICON_MARGIN_RIGHT}px`,
    }),
    connectedDeploymentTargets: css({
        display: "flex",
        alignItems: "center",
        gap: space[8],
    }),
    cloudTargetDiscoveryEnabled: css({
        display: "flex",
        alignItems: "center",
    }),
};
css({
    display: "flex",
    alignItems: "center",
    marginRight: space[16],
    marginLeft: space[16],
    marginBottom: "22.5px",
    paddingLeft: `${ICON_WIDTH + ICON_MARGIN_RIGHT}px`,
});
const statusTextStyles = css({
    fontSize: fontSize.medium,
    lineHeight: lineHeight.xSmall,
});
const contextualHelpContainerStyles = css({
    marginLeft: space["8"],
});
