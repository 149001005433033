import { css, cx } from "@emotion/css";
import { IconButton } from "@octopusdeploy/design-system-components";
import { text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { useQuery } from "@octopusdeploy/octopus-react-client";
import type { DeploymentTargetResource, NamedResource } from "@octopusdeploy/octopus-server-client";
import type { KubernetesLiveStatusResource, KubernetesLiveStatusResourceObject } from "@octopusdeploy/octopus-server-client/src/resources/kubernetesLiveStatusResource";
import { groupBy } from "lodash";
import * as React from "react";
import { K8sResourceIcon } from "~/areas/projects/components/Observability/KubernetesResources";
import type { LiveObjectStatusFilter } from "~/areas/projects/components/Observability/LiveStatusFiltering";
import { filterResources } from "~/areas/projects/components/Observability/LiveStatusFiltering";
import { DegradedIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/DegradedIcon";
import { HealthyIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/HealthyIcon";
import { InSyncIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/InSyncIcon";
import { MissingIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/MissingIcon";
import { OutOfSyncIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/OutOfSyncIcon";
import { ProgressingIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/ProgressingIcon";
import { UnknownIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/UnknownIcon";
import { DataTable, DataTableBody, DataTableHeader, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
type RefreshingLiveStatusTableProps = {
    kubernetesLiveStatusResources: KubernetesLiveStatusResource;
    deploymentTargets: DeploymentTargetResource[];
    environmentId: string;
    projectId: string;
    tenantId?: string;
    filter: LiveObjectStatusFilter;
};
export function RefreshingLiveStatusTable({ deploymentTargets, environmentId, kubernetesLiveStatusResources, projectId, tenantId, filter }: RefreshingLiveStatusTableProps) {
    const kubernetesLiveStatusQuery = useQuery((repository) => repository.KubernetesLiveStatus.getForApplicationInstance(projectId, environmentId, tenantId), [environmentId, projectId, tenantId], "Fetch K8s Live Data", {
        initialResult: kubernetesLiveStatusResources,
        refetchIntervalInMs: 1000,
    });
    const deploymentTargetQuery = useQuery((repository) => repository.Machines.all({ ids: kubernetesLiveStatusQuery.result.Resources.map((r) => r.MachineId) }), [kubernetesLiveStatusQuery.result], "Fetch K8s Live Data", {
        initialResult: deploymentTargets,
        refetchIntervalInMs: 1000,
    });
    const resourcesToRender = filterResources(kubernetesLiveStatusQuery.result.Resources, filter);
    return <LiveStatusTable deploymentTargets={deploymentTargetQuery.result} kubernetesLiveStatusResources={resourcesToRender}/>;
}
export function LiveStatusTable({ deploymentTargets, kubernetesLiveStatusResources }: {
    deploymentTargets: NamedResource[];
    kubernetesLiveStatusResources: KubernetesLiveStatusResourceObject[];
}) {
    const machineIndexedKubernetesObjects = groupBy(kubernetesLiveStatusResources, (r) => r.MachineId);
    const machineIndexedDeploymentTargets = groupBy(deploymentTargets, (r) => r.Id);
    return (<DataTable title="Live Objects">
            <DataTableHeader>
                <DataTableRow>
                    <HeaderCell text="name"/>
                    <HeaderCell text="kind"/>
                    <HeaderCell text="ready"/>
                    <HeaderCell text="namespace"/>
                    <HeaderCell text="status"/>
                </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
                {Object.keys(machineIndexedKubernetesObjects).map((machineId) => (<MachineAndChildren key={machineId} target={machineIndexedDeploymentTargets[machineId] === undefined ? { Name: machineId, Id: machineId } : machineIndexedDeploymentTargets[machineId][0]} k8sResources={machineIndexedKubernetesObjects[machineId]} startExpanded={Object.keys(machineIndexedKubernetesObjects).length === 1}/>))}
            </DataTableBody>
        </DataTable>);
}
function HeaderCell(props: {
    text: string;
}) {
    return (<DataTableRowColumn>
            <h2 className={styles.headerRow}>{props.text}</h2>
        </DataTableRowColumn>);
}
type MachineAndChildrenProps = {
    target: {
        Name: string;
        Id: string;
    };
    k8sResources: KubernetesLiveStatusResourceObject[];
    startExpanded: boolean;
};
function MachineAndChildren({ target, k8sResources, startExpanded }: MachineAndChildrenProps) {
    const [open, setOpen] = React.useState(startExpanded);
    return (<>
            <DataTableRow className={styles.machineRow}>
                <DataTableRowColumn colSpan={5} fullWidth={true} fullHeight={false} key={target.Id}>
                    <div className={styles.multiItemCellFlexContainer}>
                        <CollapseIconOrSpacer name={`Toggle ${target.Name}`} showCollapse={k8sResources && k8sResources.length > 0} isOpen={open} onClick={() => setOpen(!open)}/>
                        <p className={styles.machineText}>{target.Name}</p>
                    </div>
                </DataTableRowColumn>
            </DataTableRow>
            {open && k8sResources && k8sResources.map((resource, index) => <Row resource={resource} key={`${target.Id}child${index}`} rowKey={`${target.Id}-child-${index}`} indentLevel={1}/>)}
        </>);
}
function Row({ indentLevel, rowKey, resource }: {
    indentLevel: number;
    rowKey: string;
    resource: KubernetesLiveStatusResourceObject;
}) {
    const [open, setOpen] = React.useState(false);
    return (<>
            <DataTableRow displayNoBorder={true}>
                <DataTableRowColumn fullWidth={true} className={styles.centredTableContent}>
                    <NameAndSymbols resource={resource} indentLevel={indentLevel} isOpen={open} onClick={() => setOpen(!open)}/>
                </DataTableRowColumn>
                <TextCell text={resource.Kind}/>
                <TextCell text=""/>
                <TextCell text={resource.Namespace ? resource.Namespace : "-"}/>
                <DataTableRowColumn className={styles.centredTableContent}>
                    <div className={cx(styles.multiItemCellFlexContainer, styles.fixedWidthStatus)}>
                        <TrafficLight resource={resource} aria-labelledby={`${rowKey}status`}/>
                        <p className={cx(styles.tableCellText, styles.leftPad)} id={`${rowKey}status`}>
                            {resource.Status}
                        </p>
                    </div>
                </DataTableRowColumn>
            </DataTableRow>
            {open && resource.Children.map((resource, index) => <Row resource={resource} key={`${rowKey}child${index}`} rowKey={`${rowKey}-child-${index}`} indentLevel={indentLevel + 1}/>)}
        </>);
}
function TextCell({ text }: {
    text: string;
}) {
    return (<DataTableRowColumn className={styles.centredTableContent}>
            <p className={styles.tableCellText}>{text}</p>
        </DataTableRowColumn>);
}
function NameAndSymbols({ indentLevel, isOpen, onClick, resource }: {
    resource: KubernetesLiveStatusResourceObject;
    indentLevel: number;
    isOpen: boolean;
    onClick: () => void;
}) {
    const pixelsPerIndent = 40;
    const indent = pixelsPerIndent * indentLevel;
    const hasChildNodes = resource.Children.length > 0;
    return (<div className={cx(styles.multiItemCellFlexContainer, styles.indented(indent))}>
            <CollapseIconOrSpacer name={`Toggle ${resource.Name}`} showCollapse={hasChildNodes} isOpen={isOpen} onClick={onClick}/>
            <div className={cx(styles.multiItemCellFlexContainer, styles.leftPad)}>
                <K8sResourceIcon resourceType={resource.Kind}/>
            </div>
            <p className={cx(styles.tableCellText, styles.leftPad)}>{resource.Name}</p>
        </div>);
}
function CollapseIconOrSpacer({ name, isOpen, onClick, showCollapse }: {
    name: string;
    showCollapse: boolean;
    isOpen: boolean;
    onClick: () => void;
}) {
    const iconHeight = 24;
    const iconWidth = 48;
    if (showCollapse) {
        return (<div className={styles.iconPadding}>
                <IconButton onClick={onClick} icon={isOpen ? "ChevronDownIcon" : "ChevronRightIcon"} accessibleName={name}/>
            </div>);
    }
    else {
        return <div className={styles.iconSpacer(iconHeight, iconWidth)}/>;
    }
}
const healthyStatuses = ["Healthy", "Running"];
function TrafficLight({ resource }: {
    resource: KubernetesLiveStatusResourceObject;
}) {
    if (resource.Status === "Progressing") {
        return <ProgressingIcon />;
    }
    else if (healthyStatuses.includes(resource.Status) || resource.Status.includes("Available") || resource.Status.includes("Ready")) {
        return <HealthyIcon />;
    }
    else if (resource.Status === "InSync") {
        return <InSyncIcon />;
    }
    else if (resource.Status === "OutOfSync" || resource.Status === "Pending") {
        return <OutOfSyncIcon />;
    }
    else if (resource.Status === "Degraded") {
        return <DegradedIcon />;
    }
    else if (resource.Status === "Missing") {
        return <MissingIcon />;
    }
    return <UnknownIcon />;
}
const styles = {
    machineRow: css({
        background: themeTokens.color.background.secondary.default,
    }),
    machineText: css({
        font: text.regular.default.medium,
        margin: 0,
    }),
    multiItemCellFlexContainer: css({
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "0px",
        height: "100%",
    }),
    tableCellText: css({
        font: text.regular.default.medium,
        display: "inline-block",
        margin: 0,
    }),
    leftPad: css({
        paddingLeft: "8px",
    }),
    centredTableContent: css({
        "&&": { verticalAlign: "middle" },
    }),
    fixedWidthStatus: css({
        width: "105px", //this is literally the width of a "Progressing" word and icon
    }),
    headerRow: css({
        color: themeTokens.color.text.secondary,
        font: text.heading.xSmall,
        margin: 0,
        textTransform: "uppercase",
    }),
    iconPadding: css({
        paddingLeft: "12px",
        paddingRight: "12px",
    }),
    indented(pixels: number) {
        return css({ paddingLeft: pixels });
    },
    iconSpacer(height: number, width: number) {
        return css({
            height: height,
            flexBasis: width,
            flexGrow: 0,
            flexShrink: 0,
        });
    },
};
