import { css } from "@emotion/css";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import React from "react";
import { useIsMobile } from "../../hooks";
import type { DesignSystemLinkHref } from "../../routing/OctopusRoutingContext";
import { ActionButton, ActionButtonType, NavigationButton, NavigationButtonType } from "../Button";
import type { MenuTargetAriaAttributes } from "../Menu";
import { OverflowMenu } from "../Menu";
import type { SimpleMenuItem } from "../MenuItems";
import { Tooltip } from "../Tooltip";
export interface PageActionsProps {
    primaryAction?: PrimaryPageAction;
    actions?: PageAction[];
    overflowActions?: SimpleMenuItem[];
}
export function PageActions({ primaryAction, actions, overflowActions }: PageActionsProps) {
    const isMobile = useIsMobile();
    const hasPrimaryActionWithPermission = primaryAction !== undefined && (primaryAction.hasPermissions === undefined || primaryAction.hasPermissions);
    const actionsWithPermissions = React.useMemo(() => actions?.filter((x) => x.hasPermissions === undefined || x.hasPermissions).map(toInternalPageAction) ?? [], [actions]);
    if (actionsWithPermissions.length === 0 && overflowActions?.length === 0 && !hasPrimaryActionWithPermission) {
        return null;
    }
    const pageHasOverflowActions = overflowActions && overflowActions.length > 0;
    return (<div className={pageActionsContainerStyles}>
            {actionsWithPermissions.map((item) => (<ExtraContextForPageActionComponent key={item.type === "custom" ? item.key : item.label} extraContext={item.extraContext}>
                    <PageActionComponent item={item}/>
                </ExtraContextForPageActionComponent>))}
            {hasPrimaryActionWithPermission && (<ExtraContextForPageActionComponent key="primary" extraContext={primaryAction.extraContext}>
                    <PageActionComponent key="primary" item={toInternalPrimaryPageAction(primaryAction)}/>
                </ExtraContextForPageActionComponent>)}
            {pageHasOverflowActions && <OverflowMenu menuItems={overflowActions}/>}
        </div>);
}
const pageActionsContainerStyles = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
    flexWrap: "wrap",
});
function toInternalPrimaryPageAction(action: PrimaryPageAction): InternalPageAction {
    return {
        ...action,
        buttonType: "primary",
    };
}
function toInternalPageAction(action: PageAction): InternalPageAction {
    switch (action.type) {
        case "button":
            return { ...action, buttonType: action.buttonType ?? "secondary" };
        case "navigate":
            return { ...action, buttonType: action.buttonType ?? "secondary" };
        case "custom":
            return action;
        default:
            exhaustiveCheck(action, "Not all action types have been handled");
    }
}
interface SharedPageActionProps {
    hasPermissions?: boolean;
}
type RestrictButtonType<T extends {
    buttonType: unknown;
}, K extends T["buttonType"]> = Omit<T, "buttonType"> & {
    buttonType: K;
};
type ExcludeButtonType<T> = T extends {
    buttonType: unknown;
} ? Omit<T, "buttonType"> : T;
type ConsumerAllowedNavigationButtonTypes = "secondary" | "tertiary" | "loud";
type InternalAllowedNavigationButtonTypes = "primary" | ConsumerAllowedNavigationButtonTypes;
interface InternalNavigatePageAction extends SharedPageActionProps {
    type: "navigate";
    label: string;
    path: DesignSystemLinkHref;
    onClick?: () => void;
    disabled?: boolean;
    titleAltText?: string;
    accessibleName?: string;
    buttonType: InternalAllowedNavigationButtonTypes;
    external?: boolean;
    icon?: JSX.Element;
}
type NavigatePageAction = RestrictButtonType<InternalNavigatePageAction, ConsumerAllowedNavigationButtonTypes>;
type ConsumerAllowedButtonTypes = "secondary" | "tertiary" | "destructive";
type InternalAllowedButtonTypes = ConsumerAllowedButtonTypes | "primary";
interface InternalButtonPageAction extends SharedPageActionProps {
    type: "button";
    label: string;
    formId?: string;
    accessibleName?: string;
    disabled?: boolean | Promise<unknown>;
    onClick: (event: React.MouseEvent | undefined) => Promise<unknown> | void;
    menuButtonAttributes?: MenuTargetAriaAttributes;
    buttonType: InternalAllowedButtonTypes;
    busyLabel?: string;
    icon?: JSX.Element;
}
type ButtonPageAction = RestrictButtonType<InternalButtonPageAction, ConsumerAllowedButtonTypes>;
interface ExtraContextForPageAction {
    extraContext?: string;
}
interface InternalCustomPageAction extends SharedPageActionProps {
    type: "custom";
    key: string;
    content: React.ReactNode;
}
type CustomPageAction = InternalCustomPageAction;
type AllPageActions = NavigatePageAction | ButtonPageAction | CustomPageAction;
export type PageAction = AllPageActions & ExtraContextForPageAction;
type AllInternalPageActions = InternalNavigatePageAction | InternalButtonPageAction | InternalCustomPageAction;
type InternalPageAction = AllInternalPageActions & ExtraContextForPageAction;
export type PrimaryPageAction = ExcludeButtonType<AllInternalPageActions> & ExtraContextForPageAction;
interface PageActionComponentProps {
    item: InternalPageAction;
}
function PageActionComponent({ item }: PageActionComponentProps) {
    switch (item.type) {
        case "button":
            return <ButtonPageActionComponent item={item}/>;
        case "navigate":
            return <NavigatePageActionComponent item={item}/>;
        case "custom":
            return <CustomPageActionComponent item={item}/>;
        default:
            exhaustiveCheck(item, "Unexpected simple menu item type");
    }
}
interface ButtonPageActionComponentProps {
    item: InternalButtonPageAction;
}
function ButtonPageActionComponent({ item }: ButtonPageActionComponentProps) {
    return (<ActionButton label={item.label} formId={item.formId} busyLabel={item.busyLabel} onClick={item.onClick} type={getButtonType(item.buttonType)} accessibleName={item.accessibleName} disabled={item.disabled} menuButtonAttributes={item.menuButtonAttributes} icon={item.icon}/>);
}
function getButtonType(buttonType: InternalAllowedButtonTypes): ActionButtonType {
    switch (buttonType) {
        case "primary":
            return ActionButtonType.Primary;
        case "destructive":
            return ActionButtonType.Delete;
        case "secondary":
            return ActionButtonType.Secondary;
        case "tertiary":
            return ActionButtonType.Ternary;
        default:
            exhaustiveCheck(buttonType, "Not all button types have been handled");
    }
}
interface NavigatePageActionComponentProps {
    item: InternalNavigatePageAction;
}
function getNavigationButtonType(buttonType: InternalAllowedNavigationButtonTypes) {
    switch (buttonType) {
        case "primary":
            return NavigationButtonType.Primary;
        case "secondary":
            return NavigationButtonType.Secondary;
        case "tertiary":
            return NavigationButtonType.Ternary;
        case "loud":
            return NavigationButtonType.Loud;
        default:
            exhaustiveCheck(buttonType, "Not all navigation button types have been handled");
    }
}
function NavigatePageActionComponent({ item }: NavigatePageActionComponentProps) {
    return <NavigationButton type={getNavigationButtonType(item.buttonType)} label={item.label} onClick={item.onClick} href={item.path} external={item.external} icon={item.icon} disabled={item.disabled} titleAltText={item.titleAltText}/>;
}
function ExtraContextForPageActionComponent({ extraContext, children }: React.PropsWithChildren<ExtraContextForPageAction>) {
    if (extraContext) {
        return <Tooltip content={extraContext}>{children}</Tooltip>;
    }
    return <>{children}</>;
}
interface CustomPageActionComponentProps {
    item: InternalCustomPageAction;
}
function CustomPageActionComponent({ item }: CustomPageActionComponentProps) {
    return <>{item.content}</>;
}
