import { Level1PageLayout, Level2PageLayout } from "@octopusdeploy/design-system-components";
import { ProcessType } from "@octopusdeploy/octopus-server-client";
import type { RedirectRouteDefinition, RouteTemplate, UnknownQueryParam } from "@octopusdeploy/portal-routes";
import { allRoutes, links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { Switch, useLocation } from "react-router-dom";
import { PlatformHubLayout } from "~/areas/blueprints/PlatformHubLayout";
import ConfigurationLayout from "~/areas/configuration/components/ConfigurationLayout/ConfigurationLayout";
import DeploymentToProjectTaskRedirect from "~/areas/deployments/DeploymentToProjectTaskRedirect";
import { MachineLayout, WorkerMachineLayout } from "~/areas/infrastructure/components/MachineLayout";
import { InsightsReportLoader } from "~/areas/insights/components/InsightsReportLayout/InsightsReportLoader";
import LicenseChecker from "~/areas/insights/components/InsightsRoutes/LicenseChecker";
import ActionTemplateLayout from "~/areas/library/components/ActionTemplates/ActionTemplateLayout";
import { RedirectFeedBasedOnType } from "~/areas/library/components/ExternalFeeds/RedirectFeedBasedOnType";
import { ProjectLayout } from "~/areas/projects/components/ProjectLayout/ProjectLayout";
import type { ProjectStatus } from "~/areas/projects/components/ProjectStatus/useProjectStatus";
import BranchAwareRedirect from "~/areas/projects/components/ProjectsRoutes/BranchAwareRedirect";
import ProjectTenantVariablesRedirect from "~/areas/projects/components/ProjectsRoutes/ProjectTenantVariablesRedirect";
import { ProjectToOverviewRedirect } from "~/areas/projects/components/ProjectsRoutes/ProjectToOverviewRedirect";
import { VariablesBranchAwareRedirect } from "~/areas/projects/components/ProjectsRoutes/VariablesBranchAwareRedirect";
import RunbookContextLayout from "~/areas/projects/components/Runbooks/RunbookContextLayout";
import RunbookRunToRunbookTaskRedirect from "~/areas/runbookRuns/RunbookRunToRunbookTaskRedirect";
import { InterruptionToProjectRedirect } from "~/areas/tasks/components/TaskRoutes/InterruptionToTaskRedirect";
import TenantLayout from "~/areas/tenants/TenantLayout/TenantLayout";
import UserProfileLayout from "~/areas/users/UserProfileLayout";
import { session } from "~/clientInstance";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { NoPermissionsPage } from "~/components/NoPermissionsPage/NoPermissionsPage";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import RedirectToProcessEditorViaSlug from "~/components/RedirectToProcessEditorViaSlug/RedirectToProcessEditorViaSlug";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import { ChannelRedirect, ReleaseRedirect, RunbookRedirect } from "~/components/RootRoutes/ProjectChildRedirect";
import { RunbookSnapshotRedirect } from "~/components/RootRoutes/ProjectRunbookChildRedirect";
import { ProjectTriggerRedirect } from "~/components/RootRoutes/ProjectTriggerRedirect";
import { SpaceRootRedirect } from "~/components/RootRoutes/SpaceRootRedirect";
import { RedirectProjectIfNotSlug, RedirectReleaseVersion } from "~/components/SlugSafeRedirect/SlugSafeRedirect";
import type { SpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { isSpaceNotFound, isSpecificSpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { SpaceNotFound } from "~/components/SpaceNotFound/SpaceNotFound";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import type { PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
import type { ProjectPageContext, ProjectPageLoaderContext, ProjectRunbookPageContext, ProjectRunbookPageLoaderContext } from "~/routing/pageRegistrations/ProjectPageRegistration";
import type { StandardLoaderContext } from "~/routing/pageRegistrations/StandardLoaderContext";
import type { StandardPageContext } from "~/routing/pageRegistrations/StandardPageContext";
import { formatRoutePath } from "~/routing/pageRegistrations/formatRoutePath";
import { routeSegment } from "~/routing/pageRegistrations/routeSegment";
import { DefaultConfigurationPageRedirect } from "~/routing/redirects/DefaultConfigurationPageRedirect";
import { ScopedUserRoleToTeamRedirect } from "~/routing/redirects/ScopedUserRoleToTeamRedirect";
import type { SpecificStepTemplatePageContext } from "./pageRegistrations/LibraryPageRegistration";
import { RoutePage } from "./pageRegistrations/RoutePage";
import { allPages } from "./pageRegistrations/allPageRegistrations";
import { DefaultInsightsPageRedirect } from "./redirects/DefaultInsightsPageRedirect";
import { DefaultLibraryPageRedirect } from "./redirects/DefaultLibraryPageRedirect";
interface AllPageRoutesProps {
    spaceContext: SpaceContext;
    loaderContext: StandardLoaderContext;
    pageContext: StandardPageContext;
    scrollAreaRef?: React.RefObject<HTMLDivElement>;
    projectStatus?: ProjectStatus;
}
export function AllPageRoutes({ spaceContext, loaderContext, pageContext, scrollAreaRef, projectStatus }: AllPageRoutesProps) {
    const isBlueprintsEnabled = useOctopusFeatureToggle("blueprints", false);
    return (<Switch>
            {getDefaultRootRedirectRoute(spaceContext)}
            {getSpaceRoutes(spaceContext, loaderContext, pageContext, isBlueprintsEnabled, scrollAreaRef, projectStatus)}
            {getSystemOrMixedScopeRoutes(spaceContext, loaderContext, pageContext)}

            {routeSegment(allPages.childGroups.system.childGroups.currentUser, (routeProps, pages) => (<CurrentUserRoutes pages={pages} loaderContext={loaderContext} pageContext={pageContext}/>))}
            {renderPageWithLayout(allPages.childGroups.system.pages.styleGuidePage, loaderContext, pageContext)}

            {getNoMatchRoute(spaceContext)}
        </Switch>);
}
function getDefaultRootRedirectRoute(spaceContext: SpaceContext) {
    if (isSpecificSpaceContext(spaceContext)) {
        // The SpaceLoader redirects to the root of a space, means that this route is never hit. We should consider whether this is still needed.
        return renderRedirect(allRoutes.redirects.rootRedirect, () => <InternalRedirect to={links.projectsPage.generateUrl({ spaceId: spaceContext.Id })}/>);
    }
    if (session.currentPermissions?.hasAnyPermissions()) {
        return renderRedirect(allRoutes.redirects.rootRedirect, () => <InternalRedirect to={links.configurationRootRedirect.generateUrl()}/>);
    }
    return undefined;
}
function getSpaceRoutes(spaceContext: SpaceContext, loaderContext: StandardLoaderContext, pageContext: StandardPageContext, isBlueprintsEnabled: boolean, scrollAreaRef?: React.RefObject<HTMLDivElement>, projectStatus?: ProjectStatus) {
    if (!isSpecificSpaceContext(spaceContext)) {
        // If you are not in a space, then it does not make sense to render any space specific components
        // We are better off letting this fall through to a 404,
        // rather than try and fail to render a space specific component, and probably end up with undefined behaviour
        return [];
    }
    const blueprintSegment = isBlueprintsEnabled
        ? [routeSegment(allPages.childGroups.system.childGroups.platformHub, (routeProps, pages, childGroups) => <PlatformHubRoutes pages={pages} loaderContext={loaderContext} pageContext={pageContext}/>)]
        : [];
    return [
        ...renderPagesWithLayout(allPages.childGroups.space.pages, loaderContext, pageContext),
        routeSegment(allPages.childGroups.space.childGroups.projects, (routeProps, pages, childGroups) => (<ProjectsRoutes projectsPages={pages} projectsPageGroups={childGroups} loaderContext={loaderContext} pageContext={pageContext} scrollAreaRef={scrollAreaRef} projectStatus={projectStatus}/>)),
        routeSegment(allPages.childGroups.space.childGroups.infrastructure, (routeProps, pages, childGroups) => <InfrastructureRoutes pages={pages} childGroups={childGroups} loaderContext={loaderContext} pageContext={pageContext}/>),
        routeSegment(allPages.childGroups.space.childGroups.library, (routeProps, pages, childGroups) => <LibraryRoutes pages={pages} childGroups={childGroups} loaderContext={loaderContext} pageContext={pageContext}/>),
        routeSegment(allPages.childGroups.space.childGroups.tenants, (routeProps, pages, childGroups) => <TenantRoutes tenantsPages={pages} tenantsPageGroups={childGroups} loaderContext={loaderContext} pageContext={pageContext}/>),
        routeSegment(allPages.childGroups.space.childGroups.insights, (routeProps, pages, childGroups) => (<InsightsRoutes pages={pages} childGroups={childGroups} spaceId={routeProps.spaceId} loaderContext={loaderContext} pageContext={pageContext}/>)),
        ...blueprintSegment,
        renderRedirect(allRoutes.childRouteSegments.space.redirects.deploymentToProjectTaskRedirect, ({ spaceId, deploymentId }) => <DeploymentToProjectTaskRedirect spaceId={spaceId} deploymentId={deploymentId}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.spaceRootRedirect, ({ spaceId }) => <SpaceRootRedirect spaceId={spaceId}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.releaseRedirect, ({ spaceId, releaseId }) => <ReleaseRedirect spaceId={spaceId} releaseId={releaseId}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.triggerRedirect, ({ spaceId, triggerId }) => <ProjectTriggerRedirect spaceId={spaceId} triggerId={triggerId}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.channelRedirect, ({ spaceId, channelId }) => <ChannelRedirect spaceId={spaceId} channelId={channelId}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.runbookSnapshotRedirect, ({ spaceId, runbookSnapshotId }) => <RunbookSnapshotRedirect spaceId={spaceId} runbookSnapshotId={runbookSnapshotId}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.runbookRedirect, ({ spaceId, runbookId }) => <RunbookRedirect spaceId={spaceId} runbookId={runbookId}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.runbookRunRedirect, ({ spaceId, runbookRunId }) => <RunbookRunToRunbookTaskRedirect spaceId={spaceId} runbookRunId={runbookRunId}/>),
    ];
}
function getSystemOrMixedScopeRoutes(spaceContext: SpaceContext, loaderContext: StandardLoaderContext, pageContext: StandardPageContext) {
    if (isSpaceNotFound(spaceContext)) {
        // If the space was not found, we don't want to show routes that are available in the system context.
        // We want to direct people to go to either a space-less route, or to step into a space.
        // So we should only show the Space Switcher in the navbar and disallow other routing.
        return [];
    }
    const { scriptConsolePage, taskPage, styleGuidePage, ...systemPages } = allPages.childGroups.system.pages;
    return [
        // Render the script console page before the task page so /console is not interpreted as a taskId
        renderPageWithLayout(scriptConsolePage, loaderContext, pageContext),
        renderPageWithLayout(taskPage, loaderContext, pageContext),
        ...renderPagesWithLayout(systemPages, loaderContext, pageContext),
        routeSegment(allPages.childGroups.system.childGroups.configuration, (routeProps, pages, childGroups) => <ConfigurationRoutes pages={pages} childGroups={childGroups} loaderContext={loaderContext} pageContext={pageContext}/>),
        renderRedirect(allRoutes.childRouteSegments.system.redirects.interruptionToTaskRedirect, ({ interruptionId }) => <InterruptionToProjectRedirect interruptionId={interruptionId}/>),
    ];
}
function getNoMatchRoute(spaceContext: SpaceContext) {
    if (isSpaceNotFound(spaceContext)) {
        return <ReloadableRoute key="space not found" render={() => <SpaceNotFound spaceNotFoundContext={spaceContext}/>}/>;
    }
    else if (!session.currentPermissions?.hasAnyPermissions()) {
        return <ReloadableRoute key="no permissions" component={NoPermissionsPage}/>;
    }
    return <RedirectAs404 key="404 redirect"/>;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteParamsFromRouteTemplate<Template extends RouteTemplate<any>> = Template extends RouteTemplate<infer RouteParams> ? RouteParams : never;
const branchNameKey: Extract<keyof RouteParamsFromRouteTemplate<typeof allPages.childGroups.space.childGroups.projects.childGroups.specificProject.childGroups.branch.partialRoute>, "branchName"> = "branchName";
function InfrastructureRoutes({ pages, childGroups, loaderContext, pageContext, }: {
    pages: typeof allPages.childGroups.space.childGroups.infrastructure.pages;
    childGroups: typeof allPages.childGroups.space.childGroups.infrastructure.childGroups;
    loaderContext: StandardLoaderContext;
    pageContext: StandardPageContext;
}) {
    const { createProxyPage, editProxyPage, createMachinePolicyPage, editMachinePolicyPage, createInfrastructureAccountPage, editInfrastructureAccountPage, deploymentTargetsPage, workerMachinesPage, ...otherPages } = pages;
    return (<Switch>
            {/* Explicit rendering of create/edit page pairs so that create is rendered first and isn't caught by the edit's id param */}
            {renderPageWithLayout(createProxyPage, loaderContext, pageContext)}
            {renderPageWithLayout(editProxyPage, loaderContext, pageContext)}
            {renderPageWithLayout(createMachinePolicyPage, loaderContext, pageContext)}
            {renderPageWithLayout(editMachinePolicyPage, loaderContext, pageContext)}
            {renderPageWithLayout(createInfrastructureAccountPage, loaderContext, pageContext)}
            {renderPageWithLayout(editInfrastructureAccountPage, loaderContext, pageContext)}

            {/* These two pages are currently feature toggled between level 1 and level 2 and currently handle their layouts internally. Once the toggle is removed, these manual renders can be removed. */}
            {renderPage(deploymentTargetsPage, loaderContext, pageContext)}
            {renderPage(workerMachinesPage, loaderContext, pageContext)}

            {renderPagesWithLayout(otherPages, loaderContext, pageContext)}

            {routeSegment(childGroups.deploymentTarget, (routeProps, deploymentTargetPages) => (<MachineLayout>
                    <Switch>
                        {renderPages(deploymentTargetPages, loaderContext, pageContext)}
                        <RedirectAs404 />
                    </Switch>
                </MachineLayout>))}

            {routeSegment(childGroups.workerMachine, (routeProps, workerMachinePages) => (<WorkerMachineLayout>
                    <Switch>
                        {renderPages(workerMachinePages, loaderContext, pageContext)}
                        <RedirectAs404 />
                    </Switch>
                </WorkerMachineLayout>))}
            {renderRedirect(allRoutes.childRouteSegments.space.childRouteSegments.infrastructure.redirects.infrastructureRootRedirect, ({ spaceId }) => (<InternalRedirect to={links.infrastructureOverviewPage.generateUrl({ spaceId })}/>))}
            <RedirectAs404 />
        </Switch>);
}
function LibraryRoutes({ pages, childGroups, loaderContext, pageContext, }: {
    pages: typeof allPages.childGroups.space.childGroups.library.pages;
    childGroups: typeof allPages.childGroups.space.childGroups.library.childGroups;
    loaderContext: StandardLoaderContext;
    pageContext: StandardPageContext;
}) {
    const { archiveCertificatePage, createCertificatePage, editCertificatePage, createFeedPage, editFeedPage, createLifecyclePage, editLifecyclePage, createScriptModulePage, editScriptModulePage, createTagSetPage, editTagSetPage, createGitCredentialPage, editGitCredentialPage, gitConnectionsPage, createGitHubConnectionPage, editGitHubConnectionPage, builtInStepTemplatesPage, communityStepTemplatesPage, ...otherPages } = pages;
    const gitHubConnectionsEnabled = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle");
    const gitHubConnectionPages = {
        gitConnectionsPage,
        createGitHubConnectionPage,
        editGitHubConnectionPage,
    };
    return (<Switch>
            {renderRedirect(allRoutes.childRouteSegments.space.childRouteSegments.library.redirects.libraryRootRedirect, ({ spaceId }) => (<DefaultLibraryPageRedirect spaceId={spaceId}/>))}

            {renderRedirect(allRoutes.childRouteSegments.space.childRouteSegments.library.redirects.redirectFeedBasedOnType, ({ spaceId, feedId }) => (<RedirectFeedBasedOnType spaceId={spaceId} feedId={feedId}/>))}

            {/* Explicit ordering here, so /archive and /create doesn't get caught by the /{id} route. */}
            {renderPageWithLayout(archiveCertificatePage, loaderContext, pageContext)}
            {renderPageWithLayout(createCertificatePage, loaderContext, pageContext)}
            {renderPageWithLayout(editCertificatePage, loaderContext, pageContext)}
            {renderPageWithLayout(createFeedPage, loaderContext, pageContext)}
            {renderPageWithLayout(editFeedPage, loaderContext, pageContext)}
            {renderPageWithLayout(createLifecyclePage, loaderContext, pageContext)}
            {renderPageWithLayout(editLifecyclePage, loaderContext, pageContext)}
            {renderPageWithLayout(createScriptModulePage, loaderContext, pageContext)}
            {renderPageWithLayout(editScriptModulePage, loaderContext, pageContext)}
            {renderPageWithLayout(createTagSetPage, loaderContext, pageContext)}
            {renderPageWithLayout(editTagSetPage, loaderContext, pageContext)}
            {renderPageWithLayout(createGitCredentialPage, loaderContext, pageContext)}
            {renderPageWithLayout(editGitCredentialPage, loaderContext, pageContext)}

            {/* Explicit ordering here, so /builtin and /community doesn't get caught by the /steptemplate/{templateId} route. */}
            {renderPageWithLayout(builtInStepTemplatesPage, loaderContext, pageContext)}
            {renderPageWithLayout(communityStepTemplatesPage, loaderContext, pageContext)}

            {gitHubConnectionsEnabled && renderPagesWithLayout(gitHubConnectionPages, loaderContext, pageContext)}
            {renderPagesWithLayout(otherPages, loaderContext, pageContext)}

            {routeSegment(childGroups.specificStepTemplate, (routeProps, stepTemplatePages) => (<ActionTemplateLayout spaceId={routeProps.spaceId} templateId={routeProps.templateId}>
                    {({ onActionTemplateUpdated }) => {
                const specificStepTemplatePageContext: SpecificStepTemplatePageContext = {
                    ...pageContext,
                    onActionTemplateUpdated,
                };
                return (<Switch>
                                {renderPages(stepTemplatePages, loaderContext, specificStepTemplatePageContext)}
                                <RedirectAs404 />
                            </Switch>);
            }}
                </ActionTemplateLayout>))}

            <RedirectAs404 />
        </Switch>);
}
function ProjectsRoutes({ projectsPages, projectsPageGroups, loaderContext, pageContext, scrollAreaRef, projectStatus, }: {
    projectsPages: typeof allPages.childGroups.space.childGroups.projects.pages;
    projectsPageGroups: typeof allPages.childGroups.space.childGroups.projects.childGroups;
    loaderContext: StandardLoaderContext;
    pageContext: StandardPageContext;
    scrollAreaRef?: React.RefObject<HTMLDivElement>;
    projectStatus?: ProjectStatus;
}) {
    const location = useLocation();
    const pageContextWithProjectStatus = {
        ...pageContext,
        projectStatus,
    };
    return (<Switch>
            {renderPagesWithLayout(projectsPages, loaderContext, pageContextWithProjectStatus)}

            {routeSegment(projectsPageGroups.importExports, (routeProps, importExportPages) => (<Switch>
                    {renderPagesWithLayout(importExportPages, loaderContext, pageContext)}
                    <RedirectAs404 />
                </Switch>))}

            {routeSegment(projectsPageGroups.specificProject, (specificProjectRouteProps, specificProjectPages, specificProjectChildGroups) => (<RedirectProjectIfNotSlug>
                    <Switch>
                        {routeSegment(specificProjectChildGroups.branch, (branchRouteProps, branchPages, branchChildGroups) => (<ProjectLayout spaceId={branchRouteProps.spaceId} projectSlug={branchRouteProps.projectSlug} branchName={branchRouteProps.branchName} isNewlyCreatedProject={new URLSearchParams(location.search).get("newlyCreatedProject")} configureProject={new URLSearchParams(location.search).get("configureProject") === "true"} defaultActionType={new URLSearchParams(location.search).get("actionType")} scrollAreaRef={scrollAreaRef} projectStatus={projectStatus}>
                                    {({ projectContext, setShowK8sStatusItem, projectStatus, scrollAreaRef }) => {
                    const projectPageContext: ProjectPageContext = {
                        ...pageContext,
                        projectContext,
                        setShowK8sStatusItem,
                        scrollAreaRef,
                        projectStatus,
                    };
                    const projectPageLoaderContext: ProjectPageLoaderContext = {
                        ...loaderContext,
                        projectContext: Promise.resolve(projectContext),
                    };
                    return (<Switch>
                                                {routeSegment(branchChildGroups.deployments, (_, branchDeploymentPages) => (<ErrorContextProvider>
                                                        <Switch>
                                                            {renderRedirect(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.branch.childRouteSegments.deployments.redirects
                                .branchDeploymentProcessStepByStepSlugRedirect, ({ projectSlug, stepSlug }) => (<RedirectToProcessEditorViaSlug processType={ProcessType.Deployment} projectSlug={projectSlug} stepSlug={stepSlug}/>))}

                                                            {renderPages(branchDeploymentPages, projectPageLoaderContext, projectPageContext)}

                                                            <RedirectAs404 />
                                                        </Switch>
                                                    </ErrorContextProvider>))}

                                                {routeSegment(branchChildGroups.variables, (_, branchVariablesPages) => (<ErrorContextProvider>
                                                        <Switch>
                                                            {renderPages(branchVariablesPages, projectPageLoaderContext, projectPageContext)}
                                                            <RedirectAs404 />
                                                        </Switch>
                                                    </ErrorContextProvider>))}

                                                {renderPages(branchPages, projectPageLoaderContext, projectPageContext)}

                                                <RedirectAs404 />
                                            </Switch>);
                }}
                                </ProjectLayout>), undefined, [branchNameKey])}
                        {routeSegment(specificProjectChildGroups.withoutBranch, (branchRouteParams, nonBranchPages, nonBranchChildGroups) => {
                return (<ProjectLayout spaceId={branchRouteParams.spaceId} projectSlug={branchRouteParams.projectSlug} branchName={undefined} isNewlyCreatedProject={new URLSearchParams(location.search).get("newlyCreatedProject")} configureProject={new URLSearchParams(location.search).get("configureProject") === "true"} defaultActionType={new URLSearchParams(location.search).get("actionType")} scrollAreaRef={scrollAreaRef} projectStatus={projectStatus}>
                                    {({ projectContext, setShowK8sStatusItem, projectStatus, scrollAreaRef }) => {
                        const projectPageContext: ProjectPageContext = {
                            ...pageContext,
                            projectContext,
                            setShowK8sStatusItem,
                            scrollAreaRef,
                            projectStatus,
                        };
                        const projectPageLoaderContext: ProjectPageLoaderContext = {
                            ...loaderContext,
                            projectContext: Promise.resolve(projectContext),
                        };
                        return (<Switch>
                                                {renderRedirect(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.redirects.projectRootRedirect, () => (<ProjectToOverviewRedirect />))}
                                                {renderRedirect(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.redirects.projectOverviewRedirect, ({ spaceId, projectSlug }) => (<InternalRedirect to={links.deploymentsPage.generateUrl({ spaceId, projectSlug })}/>))}
                                                {routeSegment(nonBranchChildGroups.deployments, (deploymentRouteParams, { releasesPage, createReleasePage, ...otherDeploymentPages }, deploymentChildGroups) => (<Switch>
                                                        {routeSegment(deploymentChildGroups.process, (_, deploymentProcessPages) => (<BranchAwareRedirect>
                                                                <ErrorContextProvider>
                                                                    <Switch>
                                                                        {renderRedirect(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.childRouteSegments.deployments
                                        .childRouteSegments.process.redirects.deploymentProcessStepByStepSlugRedirect, ({ projectSlug, stepSlug }) => (<RedirectToProcessEditorViaSlug processType={ProcessType.Deployment} projectSlug={projectSlug} stepSlug={stepSlug}/>))}

                                                                        {renderPages(deploymentProcessPages, projectPageLoaderContext, projectPageContext)}

                                                                        <RedirectAs404 />
                                                                    </Switch>
                                                                </ErrorContextProvider>
                                                            </BranchAwareRedirect>))}
                                                        {routeSegment(deploymentChildGroups.settings, (_, deploymentSettingsPages) => (<BranchAwareRedirect>
                                                                <ErrorContextProvider>
                                                                    <Switch>
                                                                        {renderPages(deploymentSettingsPages, projectPageLoaderContext, projectPageContext)}
                                                                        <RedirectAs404 />
                                                                    </Switch>
                                                                </ErrorContextProvider>
                                                            </BranchAwareRedirect>))}

                                                        {renderPage(createReleasePage, projectPageLoaderContext, projectPageContext)}

                                                        {routeSegment(deploymentChildGroups.specificRelease, (_, { editReleasePage, releasePage, createDeploymentPage, deploymentDetailsPage, ...otherReleasePages }) => (<RedirectReleaseVersion>
                                                                <Switch>
                                                                    {renderPage(editReleasePage, projectPageLoaderContext, projectPageContext)}
                                                                    {renderPage(releasePage, projectPageLoaderContext, projectPageContext)}
                                                                    {renderPage(createDeploymentPage, projectPageLoaderContext, projectPageContext)}
                                                                    {renderPage(deploymentDetailsPage, projectPageLoaderContext, projectPageContext)}
                                                                    {renderPages(otherReleasePages, projectPageLoaderContext, projectPageContext)}
                                                                    <RedirectAs404 />
                                                                </Switch>
                                                            </RedirectReleaseVersion>))}

                                                        {renderPage(releasesPage, projectPageLoaderContext, projectPageContext)}
                                                        {renderPages(otherDeploymentPages, projectPageLoaderContext, projectPageContext)}
                                                        <RedirectAs404 />
                                                    </Switch>))}
                                                {routeSegment(nonBranchChildGroups.variables, (_, variablesPages, variablesChildGroups) => (<ErrorContextProvider>
                                                        <Switch>
                                                            {routeSegment(variablesChildGroups.wrapper, (_, variablesWrapperPages) => (<VariablesBranchAwareRedirect>
                                                                        <Switch>
                                                                            {renderPages(variablesWrapperPages, projectPageLoaderContext, projectPageContext)}
                                                                            <RedirectAs404 />
                                                                        </Switch>
                                                                    </VariablesBranchAwareRedirect>), true)}

                                                            {routeSegment(variablesChildGroups.all, (_, allVariablesPages) => (<VariablesBranchAwareRedirect>
                                                                    <Switch>
                                                                        {renderPages(allVariablesPages, projectPageLoaderContext, projectPageContext)}
                                                                        <RedirectAs404 />
                                                                    </Switch>
                                                                </VariablesBranchAwareRedirect>))}

                                                            {routeSegment(variablesChildGroups.preview, (_, variablePreviewPages) => (<VariablesBranchAwareRedirect>
                                                                    <Switch>
                                                                        {renderPages(variablePreviewPages, projectPageLoaderContext, projectPageContext)}
                                                                        <RedirectAs404 />
                                                                    </Switch>
                                                                </VariablesBranchAwareRedirect>))}

                                                            {routeSegment(variablesChildGroups.tenant, (_, projectTenantVariablePages) => (<Switch>
                                                                    {renderRedirect(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.childRouteSegments.variables.childRouteSegments
                                        .tenant.redirects.projectTenantsRedirect, () => (<ProjectTenantVariablesRedirect />))}
                                                                    {renderPages(projectTenantVariablePages, projectPageLoaderContext, projectPageContext)}
                                                                    <RedirectAs404 />
                                                                </Switch>))}

                                                            {renderPages(variablesPages, projectPageLoaderContext, projectPageContext)}
                                                            <RedirectAs404 />
                                                        </Switch>
                                                    </ErrorContextProvider>))}

                                                {routeSegment(nonBranchChildGroups.specificRunbook, ({ runbookId }, runbookPages, runbookChildGroups) => (<SpecificRunbookRoutes runbookId={runbookId} pages={runbookPages} childGroups={runbookChildGroups} projectLoaderContext={projectPageLoaderContext} projectPageContext={projectPageContext}/>))}

                                                {routeSegment(nonBranchChildGroups.projectRunbooks, (_, projectRunbookPages) => (<ErrorContextProvider>
                                                        <Switch>
                                                            {renderPages(projectRunbookPages, projectPageLoaderContext, projectPageContext)}
                                                            <RedirectAs404 />
                                                        </Switch>
                                                    </ErrorContextProvider>))}

                                                {renderPages(nonBranchPages, projectPageLoaderContext, projectPageContext)}
                                                <RedirectAs404 />
                                            </Switch>);
                    }}
                                </ProjectLayout>);
            })}
                        <RedirectAs404 />
                    </Switch>
                </RedirectProjectIfNotSlug>))}
            <RedirectAs404 />
        </Switch>);
}
type SpecificRunbookPages = typeof allPages.childGroups.space.childGroups.projects.childGroups.specificProject.childGroups.withoutBranch.childGroups.specificRunbook.pages;
type SpecificRunbookChildGroups = typeof allPages.childGroups.space.childGroups.projects.childGroups.specificProject.childGroups.withoutBranch.childGroups.specificRunbook.childGroups;
function SpecificRunbookRoutes({ runbookId, pages, childGroups, projectLoaderContext, projectPageContext, }: {
    runbookId: string;
    pages: SpecificRunbookPages;
    childGroups: SpecificRunbookChildGroups;
    projectLoaderContext: ProjectPageLoaderContext;
    projectPageContext: ProjectPageContext;
}) {
    const { projectRunbookSnapshotInfoPage, projectRunbookSnapshotEditPage, projectRunbookSnapshotCreatePage, projectRunbookSnapshotsPage, runbookRunNowPage, runbookRunSnapshotNowPage, createRunbookRunForSnapshotPage, projectRunbookRunDetailPage, ...otherSpecificRunbookPages } = pages;
    const newRunbookRunsListPageEnabled = useOctopusFeatureToggle("new-runbook-runs-list-page", false);
    return (<RunbookContextLayout runbookId={runbookId}>
            {(runbookContextWithLoadedRunbook) => {
            const projectRunbookPageContext: ProjectRunbookPageContext = { ...projectPageContext, runbookContext: runbookContextWithLoadedRunbook };
            const projectRunbookPageLoaderContext: ProjectRunbookPageLoaderContext = { ...projectLoaderContext, runbookContext: Promise.resolve(runbookContextWithLoadedRunbook) };
            return (<Switch>
                        {renderRedirect(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.childRouteSegments.specificRunbook.redirects.runbookRootRedirect, ({ spaceId, projectSlug, runbookId }) => {
                    // We want to redirect to the new runbook runs list page if the feature toggle is enabled or
                    // if the runbook is a Git runbook.
                    if (newRunbookRunsListPageEnabled) {
                        return <InternalRedirect to={links.projectRunbookRunsListPage.generateUrl({ spaceId, projectSlug, runbookId })}/>;
                    }
                    return <InternalRedirect to={links.projectRunbookOverviewPage.generateUrl({ spaceId, projectSlug, runbookId })}/>;
                })}

                        {renderRedirect(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.childRouteSegments.specificRunbook.childRouteSegments.process.redirects
                    .projectRubookProcessStepByStepSlugRedirect, ({ projectSlug, processId, stepSlug }) => (<RedirectToProcessEditorViaSlug processType={ProcessType.Runbook} projectSlug={projectSlug} processId={processId} stepSlug={stepSlug}/>))}

                        {routeSegment(childGroups.process, (_, runbookProcessPages) => (<ErrorContextProvider>
                                <Switch>{renderPages(runbookProcessPages, projectRunbookPageLoaderContext, projectRunbookPageContext)}</Switch>
                            </ErrorContextProvider>))}

                        {/* Explicit ordering here, so that snapshot routes don't interfere with each other. */}
                        {renderPage(projectRunbookSnapshotCreatePage, projectRunbookPageLoaderContext, projectRunbookPageContext)}
                        {renderPage(projectRunbookSnapshotEditPage, projectRunbookPageLoaderContext, projectRunbookPageContext)}
                        {renderPage(projectRunbookSnapshotInfoPage, projectRunbookPageLoaderContext, projectRunbookPageContext)}
                        {renderPage(projectRunbookSnapshotsPage, projectRunbookPageLoaderContext, projectRunbookPageContext)}
                        {renderPage(runbookRunSnapshotNowPage, projectRunbookPageLoaderContext, projectRunbookPageContext)}
                        {renderPage(runbookRunNowPage, projectRunbookPageLoaderContext, projectRunbookPageContext)}
                        {renderPage(createRunbookRunForSnapshotPage, projectRunbookPageLoaderContext, projectRunbookPageContext)}
                        {renderPage(projectRunbookRunDetailPage, projectRunbookPageLoaderContext, projectRunbookPageContext)}
                        {renderPages(otherSpecificRunbookPages, projectRunbookPageLoaderContext, projectRunbookPageContext)}

                        <RedirectAs404 />
                    </Switch>);
        }}
        </RunbookContextLayout>);
}
function PlatformHubRoutes({ pages, loaderContext, pageContext }: {
    pages: typeof allPages.childGroups.system.childGroups.platformHub.pages;
    loaderContext: StandardLoaderContext;
    pageContext: StandardPageContext;
}) {
    return (<PlatformHubLayout>
            <Switch>
                {renderPages(pages, loaderContext, pageContext)}
                <RedirectAs404 />
            </Switch>
        </PlatformHubLayout>);
}
function ConfigurationRoutes({ pages, childGroups, loaderContext, pageContext, }: {
    pages: typeof allPages.childGroups.system.childGroups.configuration.pages;
    childGroups: typeof allPages.childGroups.system.childGroups.configuration.childGroups;
    loaderContext: StandardLoaderContext;
    pageContext: StandardPageContext;
}) {
    const allPages = {
        ...pages,
        ...childGroups.settings.pages,
    };
    const { createSubscriptionPage, editSubscriptionPage, createUserRolePage, editUserRolePage, createUserPage, editUserPage, deploymentFreezesCreatePage, deploymentFreezesEditPage, ...otherPages } = allPages;
    return (<ConfigurationLayout>
            <Switch>
                {renderRedirect(allRoutes.childRouteSegments.system.childRouteSegments.configuration.redirects.configurationRootRedirect, () => (<DefaultConfigurationPageRedirect />))}
                {renderRedirect(allRoutes.childRouteSegments.system.childRouteSegments.configuration.redirects.scopedUserRoleToTeamRedirect, ({ scopedRoleId }) => (<ScopedUserRoleToTeamRedirect scopedUserRoleId={scopedRoleId}/>))}
                {renderRedirect(allRoutes.childRouteSegments.system.childRouteSegments.configuration.redirects.diagnosticMachineCleanupRedirect, () => (<InternalRedirect to={links.auditPage.generateUrl({ documentTypes: ["Machines"], users: ["users-system"], eventCategories: ["Deleted"] })}/>))}
                {/* The routes for some create and edit pages overlap.
                 Depending on the order in which they are rendered, the /create segment of the create page
                 could be interpreted as the route parameter of the edit page.
                 Here we need to explicitly render the create page first to avoid this. */}
                {renderPage(createSubscriptionPage, loaderContext, pageContext)}
                {renderPage(editSubscriptionPage, loaderContext, pageContext)}
                {renderPage(createUserRolePage, loaderContext, pageContext)}
                {renderPage(editUserRolePage, loaderContext, pageContext)}
                {renderPage(createUserPage, loaderContext, pageContext)}
                {renderPage(editUserPage, loaderContext, pageContext)}
                {renderPage(deploymentFreezesCreatePage, loaderContext, pageContext)}
                {renderPage(deploymentFreezesEditPage, loaderContext, pageContext)}
                {renderPages(otherPages, loaderContext, pageContext)}
                <RedirectAs404 />
            </Switch>
        </ConfigurationLayout>);
}
function CurrentUserRoutes({ pages, loaderContext, pageContext }: {
    pages: typeof allPages.childGroups.system.childGroups.currentUser.pages;
    loaderContext: StandardLoaderContext;
    pageContext: StandardPageContext;
}) {
    return (<UserProfileLayout>
            <Switch>
                {renderRedirect(allRoutes.childRouteSegments.system.childRouteSegments.currentUser.redirects.currentUserRootRedirect, () => (<InternalRedirect to={links.currentUserDetailsPage.generateUrl()}/>))}
                {renderPages(pages, loaderContext, pageContext)}

                <RedirectAs404 />
            </Switch>
        </UserProfileLayout>);
}
function InsightsRoutes({ pages, childGroups, loaderContext, pageContext, }: {
    pages: typeof allPages.childGroups.space.childGroups.insights.pages;
    childGroups: typeof allPages.childGroups.space.childGroups.insights.childGroups;
    spaceId: string;
    loaderContext: StandardLoaderContext;
    pageContext: StandardPageContext;
}) {
    const { insightsUpsellPage, ...rootInsightsPages } = pages;
    return (<Switch>
            {renderRedirect(allRoutes.childRouteSegments.space.childRouteSegments.insights.redirects.insightsRootRedirect, ({ spaceId }) => (<DefaultInsightsPageRedirect spaceId={spaceId}/>))}

            {renderPageWithLayout(insightsUpsellPage, loaderContext, pageContext)}
            {renderPagesWithLayout(rootInsightsPages, loaderContext, pageContext)}

            {routeSegment(childGroups.reports, ({ spaceId }, reportsPages, reportsPagesChildren) => (<LicenseChecker spaceId={spaceId}>
                    <Switch>
                        {renderPagesWithLayout(reportsPages, loaderContext, pageContext)}
                        {routeSegment(reportsPagesChildren.specificReport, ({ spaceId, reportId }, reportPages) => (<InsightsReportLoader reportId={reportId} pageContext={pageContext}>
                                {(insightsReportPageContext) => (<Switch>
                                        {renderPages(reportPages, loaderContext, insightsReportPageContext)}
                                        <RedirectAs404 />
                                    </Switch>)}
                            </InsightsReportLoader>))}
                    </Switch>
                </LicenseChecker>))}
        </Switch>);
}
function TenantRoutes({ tenantsPages, tenantsPageGroups, loaderContext, pageContext, }: {
    tenantsPages: typeof allPages.childGroups.space.childGroups.tenants.pages;
    tenantsPageGroups: typeof allPages.childGroups.space.childGroups.tenants.childGroups;
    loaderContext: StandardLoaderContext;
    pageContext: StandardPageContext;
}) {
    return (<Switch>
            {routeSegment(tenantsPageGroups.specificTenant, ({ spaceId, tenantId }, specificTenantPages) => (<TenantLayout spaceId={spaceId} tenantId={tenantId}>
                    <Switch>
                        {renderPages(specificTenantPages, loaderContext, pageContext)}
                        <RedirectAs404 />
                    </Switch>
                </TenantLayout>))}
            {/*The tenant root pages need to be rendered after more specific pages to allow those to be matched first. */}
            {renderPagesWithLayout(tenantsPages, loaderContext, pageContext)}
            <RedirectAs404 />
        </Switch>);
}
/**
 * Renders a single page, with no layout.
 * Use this when the layout is manually rendered within AllPageRoutes. Otherwise use {@link renderPageWithLayout}.
 * @param pageRegistration The page registration to render.
 * @param loaderContext The LoaderContext to provide to the page
 * @param pageContext The PageContext to provide to the page.
 */
function renderPage<RouteParams, QueryParams extends UnknownQueryParam[], LoaderData, LoaderContext, PageContext>(pageRegistration: PageRegistration<RouteParams, QueryParams, LoaderData, LoaderContext, PageContext>, loaderContext: LoaderContext, pageContext: PageContext) {
    const routePath = formatRoutePath(pageRegistration.route.template);
    return <RoutePage<RouteParams, QueryParams, LoaderData, LoaderContext, PageContext> key={routePath} loaderContext={loaderContext} path={routePath} exact={true} pageRegistration={pageRegistration} pageContext={pageContext}/>;
}
/**
 * Renders a single page, along with the layout inferred from the page registration's level.
 * If the layout is rendered within AllPageRoutes, use {@link renderPage} instead
 * @param pageRegistration The page registration to render.
 * @param loaderContext The LoaderContext to provide to the page
 * @param pageContext The PageContext to provide to the page.
 */
function renderPageWithLayout<RouteParams, QueryParams extends UnknownQueryParam[], LoaderData, LoaderContext, PageContext>(pageRegistration: PageRegistration<RouteParams, QueryParams, LoaderData, LoaderContext, PageContext>, loaderContext: LoaderContext, pageContext: PageContext) {
    const routePath = formatRoutePath(pageRegistration.route.template);
    return (<RoutePage<RouteParams, QueryParams, LoaderData, LoaderContext, PageContext> key={routePath} loaderContext={loaderContext} path={routePath} exact={true} pageRegistration={pageRegistration} pageContext={pageContext} renderPageLayout={(page) => (pageRegistration.level === 1 ? <Level1PageLayout>{page}</Level1PageLayout> : <Level2PageLayout>{page}</Level2PageLayout>)}/>);
}
function renderPages<LoaderContext, PageContext, PageRegistrations extends Record<string, UnknownPageRegistration<LoaderContext, PageContext>>>(pageRegistrations: PageRegistrations, loaderContext: LoaderContext, pageContext: PageContext) {
    return Object.values(pageRegistrations).map((p) => renderPage(p, loaderContext, pageContext));
}
function renderPagesWithLayout<LoaderContext, PageContext, PageRegistrations extends Record<string, UnknownPageRegistration<LoaderContext, PageContext>>>(pageRegistrations: PageRegistrations, loaderContext: LoaderContext, pageContext: PageContext) {
    return Object.values(pageRegistrations).map((p) => renderPageWithLayout(p, loaderContext, pageContext));
}
function renderRedirect<TRouteParams>(path: RedirectRouteDefinition<TRouteParams>, render: (params: TRouteParams) => React.ReactNode) {
    const formattedPath = formatRoutePath(path.completeRoute.template);
    return <ReloadableRoute key={formattedPath} path={formattedPath} exact={true} render={({ match }) => render({ ...match.params })}/>;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnknownPageRegistration<LoaderContext, PageContext> = PageRegistration<any, UnknownQueryParam[], any, LoaderContext, PageContext>;
