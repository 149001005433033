import { css } from "@emotion/css";
import { fontSize, fontWeight, space } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import type { ActivityElement, KubernetesObjectStatus, KubernetesStepStatus, TaskState } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { StepWithKubernetesAction } from "~/areas/tasks/components/Task/K8sStatus/StepWithKubernetesAction";
import type { KubernetesDetailsFilter, KubernetesDetailsStepView } from "~/areas/tasks/components/Task/Kubernetes/KubernetesDetailsLayout";
import { KubernetesStepsLayoutContainerKey } from "~/areas/tasks/components/Task/Kubernetes/KubernetesStepsLayout";
import { KubernetesStepAppliedManifests } from "~/areas/tasks/components/Task/Kubernetes/Manifests/KubernetesStepAppliedManifests";
import { KubernetesStepSnapshotStatus } from "~/areas/tasks/components/Task/Kubernetes/Snapshot/KubernetesStepSnapshotStatus";
import { TaskStatusIcon } from "~/areas/tasks/components/Task/Kubernetes/TaskStatusIcon";
import SimpleExpander from "~/components/SimpleExpander/index";
export type KubernetesStepBaseProps = StepWithKubernetesAction & {
    deploymentId: string;
    stepActivity: Pick<ActivityElement, "Status" | "Ended" | "Started">;
    taskState?: TaskState;
    filter: KubernetesDetailsFilter;
    stepResourceStatus: KubernetesStepStatus | undefined;
};
type KubernetesStepStatusExpanderProps = KubernetesStepBaseProps & {
    activeView: KubernetesDetailsStepView;
    showObjectDrawer: (kos: KubernetesObjectStatus) => void;
    stepResourceStatus: KubernetesStepStatus | undefined;
};
const styles = {
    stepNameContainer: css({
        lineHeight: 1.4,
        fontSize: fontSize["base"],
    }),
    stepName: css({
        fontWeight: fontWeight["600"],
        paddingLeft: space[4],
    }),
};
export const KubernetesStepExpander = (props: KubernetesStepStatusExpanderProps) => {
    const { activeView, showObjectDrawer, ...rest } = props;
    return (<SimpleExpander key={props.stepName} accessibleName={props.stepName} title={<div className={styles.stepNameContainer}>
                    <TaskStatusIcon status={props.stepActivity.Status || ""}/> <span className={styles.stepName}>{props.stepName}</span>
                </div>} errorKey={props.stepName} containerKey={KubernetesStepsLayoutContainerKey} onDidExpand={(isExpanded) => logger.info("Expanded: {Expanded}", { Expanded: isExpanded })}>
            {activeView === "Snapshot" && <KubernetesStepSnapshotStatus showObjectDrawer={showObjectDrawer} {...rest}/>}
            {activeView === "Manifests" && <KubernetesStepAppliedManifests {...props}/>}
        </SimpleExpander>);
};
