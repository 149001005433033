import { type ReactNode } from "react";
import * as React from "react";
interface Level1PageLayoutProps {
    children: ReactNode;
}
/**
 * The `Level1PageLayout` component houses and positions the `Level1PageContent` component.
 */
export function Level1PageLayout({ children }: Level1PageLayoutProps) {
    return <div>{children}</div>;
}
