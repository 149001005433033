import { css } from "@emotion/css";
import { IconButton, Tooltip, useCurrentScrollArea } from "@octopusdeploy/design-system-components";
import { ArrowExpandIcon, DuplicateIcon, HeartCrackIcon, HeartIcon } from "@octopusdeploy/design-system-icons";
import { borderRadius, borderWidth, colorScales, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { useInlineStatusQuery } from "@octopusdeploy/octopus-react-client";
import { ActivityStatus } from "@octopusdeploy/octopus-server-client";
import type { KubernetesResourceManifestResource } from "@octopusdeploy/octopus-server-client/dist/src/resources/kubernetesResourceManifestResource";
import type { KubernetesResourceManifestSummaryResource } from "@octopusdeploy/octopus-server-client/dist/src/resources/kubernetesResourceManifestSummaryResource";
import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { KubernetesStepBaseProps } from "~/areas/tasks/components/Task/Kubernetes/KubernetesStepExpander";
import { ManifestNavigationDetails } from "~/areas/tasks/components/Task/Kubernetes/Manifests/ManifestNavigationDetails";
import { SnapshotTaken } from "~/areas/tasks/components/Task/Kubernetes/SnapshotTaken";
import type { CodeComponent } from "~/components/Code/Code";
import { Code } from "~/components/Code/Code";
import SimpleExpander from "~/components/SimpleExpander";
import { ExpansionButtons } from "~/components/form/index";
import StringHelper from "~/utils/StringHelper/index";
export const KubernetesStepAppliedManifestsContainerKeyPrefix = "KubernetesStepAppliedManifests";
const styles = {
    mainContainer: css({
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
    }),
    snapshotTakenContainer: css({
        paddingLeft: space[16],
    }),
    navigationContainer: css({
        padding: space[16],
    }),
    manifestsContainer: css({
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        paddingRight: space[16],
    }),
    navigationStickyContainer: (pageHeaderHeight: string) => css({
        width: `calc(${space["16"]} * 25)`, // 400px
        paddingLeft: space[8],
        paddingRight: space[8],
        position: "sticky",
        top: pageHeaderHeight,
        overflowY: "auto",
    }),
    targetsTreeSummary: css({
        paddingLeft: 0,
    }),
    namespacesTreeSummary: css({
        paddingLeft: space[32],
    }),
    manifestListItemList: css({
        display: "flex",
        flexDirection: "column",
        gap: space[4],
    }),
    manifestListItem: css({
        padding: space[8],
        paddingLeft: space[56],
        paddingRight: space[12],
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        gap: space[8],
        "&:hover": {
            borderRadius: borderRadius["small"],
            backgroundColor: themeTokens.color.background.primary.hovered,
        },
        "&:focus": {
            backgroundColor: themeTokens.color.background.primary.default,
            borderRadius: borderRadius["small"],
            border: `1px solid ${themeTokens.color.border.primary}`,
        },
    }),
    manifestListItemSelected: css({
        borderRadius: borderRadius["small"],
        backgroundColor: themeTokens.color.background.primary.pressed,
    }),
    manifestListItemHeader: css({
        color: themeTokens.color.text.primary,
        font: text.regular.default.medium,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    }),
    manifestListItemSubtitle: css({
        color: themeTokens.color.text.secondary,
        font: text.regular.default.xSmall,
    }),
    manifestTitleContainer: css({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexGrow: 1,
        alignItems: "center",
    }),
    manifestHeader: css({
        color: themeTokens.color.text.primary,
        font: text.regular.bold.medium,
    }),
    manifestSubtitle: css({
        color: themeTokens.color.text.primary,
        font: text.regular.default.xSmall,
    }),
    manifestActions: css({
        display: "flex",
        flexDirection: "row",
        gap: space[16],
    }),
    codeViewerContainer: css({
        marginTop: space[16],
        marginBottom: space[16],
        backgroundColor: themeTokens.color.background.secondary.default,
        border: `${borderWidth[1]} solid ${themeTokens.color.border.primary}`,
        borderRadius: borderRadius["small"],
    }),
    codeViewerContainerSelected: css({
        border: `${borderWidth[2]} solid ${themeTokens.color.border.tertiary}`,
    }),
    codeContainer: css({
        borderTop: `${borderWidth[1]} solid ${themeTokens.color.border.primary}`,
    }),
};
type TargetTreeNavItem = {
    machineId: string;
    targetName: string;
    namespaceItems: NamespaceTreeNavItem[];
};
type NamespaceTreeNavItem = {
    namespace: string;
    manifestItems: ManifestTreeNavItem[];
};
type ManifestTreeNavItem = {
    resource: KubernetesResourceManifestSummaryResource;
    name: string;
    kind: string;
    hasObjectStatus: boolean;
    isHealthy: boolean;
};
const successKosStatuses = [ActivityStatus.Success, ActivityStatus.SuccessWithWarning, "Successful"];
export type KubernetesStepAppliedManifestsProps = KubernetesStepBaseProps & {};
export const KubernetesStepAppliedManifests = (props: KubernetesStepAppliedManifestsProps) => {
    const [selectedManifestId, setSelectedManifestId] = useState<string | undefined>();
    const [navigationDetailsFullHeight, setNavigationDetailsFullHeight] = useState<number | undefined>();
    const manifestDivsRefs = useRef<Record<string, HTMLDivElement | null>>({});
    const codeComponentRefs = useRef<Record<string, CodeComponent | null>>({});
    const activeScrollArea = useCurrentScrollArea();
    const navigationDetailsRef = useRef<HTMLDivElement | null>(null);
    const containerKey = `${KubernetesStepAppliedManifestsContainerKeyPrefix}_${props.stepName}`;
    //For the sticky nav & scrollMarginTop to work, this needs to match the height of the page header
    const pageHeaderHeight = useMemo(() => {
        //get the header dom node so we can retrieve its height.
        const stickyHeaderRef = document.querySelector("[class*=\"headerSecondaryStickiedStyle\"]");
        //default to 92 px
        const heightInPx = stickyHeaderRef?.getBoundingClientRect()?.height || 92;
        //the extra 16px is just to clear the drop shadow
        return `calc(${heightInPx}px + ${space[16]})`;
    }, []);
    const { result: manifestSummaries, isLoading: isLoadingManifestSummaries, error: manifestSummariesError, } = useInlineStatusQuery(async (repo) => repo.KubernetesManifest.getKubernetesManifestSummaries(props.deploymentId), [props.deploymentId], "Kubernetes Object Manifest Summaries");
    const { result: manifestsWithContent, isLoading: isLoadingManifestsWithContent, error: manifestsWithContentError, } = useInlineStatusQuery(async (repo) => repo.KubernetesManifest.getKubernetesManifests(props.deploymentId), [props.deploymentId], "Kubernetes Object Manifest for Deployment");
    const resizeTargetDetails = useCallback(() => {
        if (!navigationDetailsRef.current) {
            return;
        }
        //we need to use the original height of the navigation details so we are correctly resizing it based on its original height
        const detailsHeight = navigationDetailsFullHeight || navigationDetailsRef.current.offsetHeight;
        if (!navigationDetailsFullHeight) {
            setNavigationDetailsFullHeight(detailsHeight);
        }
        const scrollAreaBoundingRect = activeScrollArea.getBoundingClientRect();
        const scrollAreaHeight = activeScrollArea.clientHeight;
        const boundingRect = navigationDetailsRef.current.getBoundingClientRect();
        //the top needs to account for the scroll area not being at the top
        const top = boundingRect.top - scrollAreaBoundingRect.top;
        const height = Math.max(0, boundingRect.top > 0 ? Math.min(detailsHeight, scrollAreaHeight - top) : Math.min(boundingRect.bottom, scrollAreaHeight)) - 3; // subtract 3px for a small buffer
        navigationDetailsRef.current.style.height = `${height}px`;
    }, [activeScrollArea, navigationDetailsFullHeight]);
    useEffect(() => {
        activeScrollArea.addEventListener("scroll", resizeTargetDetails);
        window.addEventListener("resize", resizeTargetDetails);
        return () => {
            window.removeEventListener("resize", resizeTargetDetails);
            activeScrollArea.removeEventListener("scroll", resizeTargetDetails);
        };
    }, [activeScrollArea, resizeTargetDetails]);
    const setRefAndResizeTargetDetails = (node: HTMLDivElement | null) => {
        navigationDetailsRef.current = node;
        resizeTargetDetails();
    };
    const manifestsTree: TargetTreeNavItem[] = useMemo(() => {
        if (!manifestSummaries) {
            return [];
        }
        //get the unique machine ids
        const machineIds = [...new Set(manifestSummaries.Resources.map((krm) => krm.MachineId))];
        //get all the unique namespaces
        const namespaces = [...new Set(manifestSummaries.Resources.map((krm) => krm.KubernetesObjectNamespace))];
        return machineIds
            .map((machineId) => {
            const perMachineResources = manifestSummaries.Resources.filter((krm) => krm.MachineId === machineId);
            return {
                machineId,
                targetName: perMachineResources[0].MachineName, //all the machines will have the same name, so just pick the first one
                namespaceItems: namespaces
                    .map((ns) => {
                    const perNamespaceResources = perMachineResources.filter((krm) => krm.KubernetesObjectNamespace === ns);
                    return {
                        namespace: StringHelper.isNullOrWhiteSpace(ns) ? "Cluster-scoped" : ns,
                        manifestItems: perNamespaceResources.map((krm) => {
                            const objectStatus = props.stepResourceStatus?.KubernetesObjects.find((kos) => kos.ManifestId === krm.Id)?.Status;
                            return {
                                resource: krm,
                                name: krm.KubernetesObjectName,
                                kind: krm.KubernetesResourceKind,
                                hasObjectStatus: !!objectStatus,
                                isHealthy: !!objectStatus && successKosStatuses.indexOf(objectStatus) !== -1,
                            };
                        }),
                    };
                })
                    .filter((t) => t.manifestItems.length > 0),
            };
        })
            .filter((t) => t.namespaceItems.length > 0);
    }, [manifestSummaries, props.stepResourceStatus?.KubernetesObjects]);
    const orderedManifests: KubernetesResourceManifestResource[] = useMemo(() => {
        if (manifestsTree.length === 0 || !manifestsWithContent) {
            return [];
        }
        return manifestsTree
            .flatMap((t1) => t1.namespaceItems.flatMap((t2) => t2.manifestItems))
            .map((t) => t.resource)
            .map((krm1) => manifestsWithContent.Resources.find((krm2) => krm1.Id === krm2.Id))
            .filter((krm) => !!krm)
            .map((krm) => ({
            ...krm,
            ManifestContent: krm?.ManifestContent?.trimEnd(),
        }));
    }, [manifestsTree, manifestsWithContent]);
    const selectManifest = (manifestId: string) => {
        setSelectedManifestId(manifestId);
        const ref = manifestDivsRefs.current[manifestId];
        if (!ref) {
            return;
        }
        //the scroll-margin is used as a sneaky way to offset the scrollIntoView
        ref.style.scrollMarginTop = pageHeaderHeight;
        ref.scrollIntoView(true);
        ref.style.scrollMarginTop = "0";
    };
    const manifestTitle = (krm: KubernetesResourceManifestResource) => (<div>
            <div className={styles.manifestHeader}>{krm.KubernetesObjectName}</div>
            <div className={styles.manifestSubtitle}>
                {krm.KubernetesResourceKind} in {krm.KubernetesObjectNamespace}
            </div>
        </div>);
    return (<>
            {props.stepResourceStatus?.LastUpdated && (<div className={styles.snapshotTakenContainer}>
                    <SnapshotTaken date={props.stepResourceStatus?.LastUpdated}/>
                </div>)}
            <div className={styles.mainContainer}>
                <div className={styles.navigationContainer}>
                    <div className={styles.navigationStickyContainer(pageHeaderHeight)} ref={setRefAndResizeTargetDetails}>
                        {manifestsTree.map((machineTreeItem) => (<ManifestNavigationDetails key={machineTreeItem.machineId} summary={machineTreeItem.targetName} summaryClassName={styles.targetsTreeSummary}>
                                {machineTreeItem.namespaceItems.map((namespaceTreeItem) => (<ManifestNavigationDetails key={namespaceTreeItem.namespace} summary={namespaceTreeItem.namespace} summaryClassName={styles.namespacesTreeSummary}>
                                        <div className={styles.manifestListItemList}>
                                            {namespaceTreeItem.manifestItems.map((manifestTreeItem) => (<div key={manifestTreeItem.resource.Id} className={cn(styles.manifestListItem, manifestTreeItem.resource.Id === selectedManifestId ? styles.manifestListItemSelected : null)} onClick={() => selectManifest(manifestTreeItem.resource.Id)}>
                                                    <div>{manifestTreeItem.hasObjectStatus ? manifestTreeItem.isHealthy ? <HeartIcon size={20} color={colorScales.green[500]}/> : <HeartCrackIcon size={20} color={colorScales.red[500]}/> : null}</div>
                                                    <div>
                                                        <div className={styles.manifestListItemHeader}>{manifestTreeItem.name}</div>
                                                        <div className={styles.manifestListItemSubtitle}>{manifestTreeItem.kind}</div>
                                                    </div>
                                                </div>))}
                                        </div>
                                    </ManifestNavigationDetails>))}
                            </ManifestNavigationDetails>))}
                    </div>
                </div>
                <div className={styles.manifestsContainer}>
                    <ExpansionButtons containerKey={containerKey} expandAllOnMount={true}/>
                    {orderedManifests.map((krm) => (<div key={krm.Id} ref={(ref) => (manifestDivsRefs.current[krm.Id] = ref)} className={cn(styles.codeViewerContainer, krm.Id === selectedManifestId ? styles.codeViewerContainerSelected : null)}>
                            <SimpleExpander containerKey={containerKey} accessibleName={krm.KubernetesObjectName} errorKey={krm.Id} title={<div className={styles.manifestTitleContainer}>
                                        {manifestTitle(krm)}
                                        <div className={styles.manifestActions}>
                                            <Tooltip content={"Copy to clipboard"}>
                                                <IconButton customIcon={<DuplicateIcon size={20}/>} onClick={async (e) => {
                    e.stopPropagation();
                    await codeComponentRefs.current[krm.Id]?.copyToClipboard();
                }}/>
                                            </Tooltip>
                                            <Tooltip content={"Enter full screen"}>
                                                <IconButton customIcon={<ArrowExpandIcon size={20}/>} onClick={(e) => {
                    e.stopPropagation();
                    codeComponentRefs.current[krm.Id]?.showInFullScreen();
                }}/>
                                            </Tooltip>
                                        </div>
                                    </div>}>
                                <div className={styles.codeContainer}>
                                    <Code ref={(ref) => (codeComponentRefs.current[krm.Id] = ref)} value={krm.ManifestContent} language={"YAML"} showLineNumbers={true} lineWrapping={true} fullScreenDialogTitle={manifestTitle(krm)}/>
                                </div>
                            </SimpleExpander>
                        </div>))}
                </div>
            </div>
        </>);
};
