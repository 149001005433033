import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, IconButton, Tooltip } from "@octopusdeploy/design-system-components";
import { DuplicateIcon } from "@octopusdeploy/design-system-icons";
import { borderWidth, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { noOp } from "@octopusdeploy/utilities";
import { indentationMarkers } from "@replit/codemirror-indentation-markers";
import ReactCodeMirror, { EditorView } from "@uiw/react-codemirror";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { TextFormat, type CodeEditorLanguage } from "~/components/CodeEditor/CodeEditor";
import { getLanguageExtensions } from "~/components/CodeEditor/CodeEditorExtensions";
import { githubDark, githubLight, yamlIndentationMarkerConfig } from "~/components/CodeEditor/CodeEditorThemes";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import { CustomDialogActions, CustomDialogTitleBar, CustomFlexDialogContent, LargeDialogFrame } from "~/components/DialogLayout/Custom/index";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import type { ThemePaletteType } from "~/theme/index";
import { codeEditorCodeMirrorBaseStyles } from "../CodeEditor/codeEditorStyles";
type CodeProps = {
    value: string | undefined;
    language: CodeEditorLanguage;
    showLineNumbers?: boolean;
    lineWrapping?: boolean;
    placeholder?: string;
    theme?: ThemePaletteType;
    fullScreenDialogTitle?: React.ReactNode;
};
export type CodeComponent = {
    copyToClipboard: () => Promise<void>;
    showInFullScreen: () => void;
};
const styles = {
    dialogTitle: css({
        backgroundColor: themeTokens.color.codeEditor.toolbar.background,
        borderBottom: `${borderWidth[1]} solid ${themeTokens.color.codeEditor.text.gutter}`,
        button: {
            padding: space[8],
        },
    }),
    dialogContent: css({
        background: themeTokens.color.codeEditor.background,
    }),
    dialogActions: css({
        backgroundColor: themeTokens.color.codeEditor.background,
        borderTop: `${borderWidth[1]} solid ${themeTokens.color.codeEditor.text.gutter}`,
    }),
};
export const Code = forwardRef<CodeComponent, CodeProps>((props: CodeProps, ref) => {
    const themePalette = useThemePaletteType();
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const copyToClipboard = async () => {
        if (props.value) {
            await navigator.clipboard.writeText(props.value);
        }
    };
    const showInFullScreen = () => {
        if (isFullScreen) {
            return;
        }
        setIsFullScreen(true);
    };
    useImperativeHandle(ref, () => ({
        copyToClipboard,
        showInFullScreen,
    }));
    const codeMirror = (<ReactCodeMirror value={props.value} theme={(props.theme || themePalette) === "dark" ? githubDark : githubLight} className={codeEditorCodeMirrorBaseStyles} basicSetup={{ lineNumbers: props.showLineNumbers || true }} indentWithTab={false} // don't use the Codemirror indentWithTab extension as it causes the current line to be indented regardless of where the cursor is
     readOnly={true} onChange={noOp} placeholder={props.placeholder} extensions={[props.lineWrapping ? EditorView.lineWrapping : [], getLanguageExtensions(props.language), props.language === TextFormat.YAML ? indentationMarkers(yamlIndentationMarkerConfig) : []]}/>);
    return (<>
            <CustomDialog open={isFullScreen} close={() => setIsFullScreen(false)} render={(renderProps) => (<LargeDialogFrame>
                        <CustomDialogTitleBar className={styles.dialogTitle} title={props.fullScreenDialogTitle} actions={<Tooltip content={"Copy to clipboard"}>
                                    <IconButton customIcon={<DuplicateIcon size={20}/>} onClick={async () => {
                    await copyToClipboard();
                }}/>
                                </Tooltip>}/>
                        <CustomFlexDialogContent className={styles.dialogContent}>{codeMirror}</CustomFlexDialogContent>
                        <CustomDialogActions className={styles.dialogActions} actions={<ActionButton label="Close" onClick={renderProps.close} type={ActionButtonType.Primary}/>}/>
                    </LargeDialogFrame>)}/>
            {codeMirror}
        </>);
});
