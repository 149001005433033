import { css, cx } from "@emotion/css";
import { ActionButton, ActionButtonType, Callout, Drawer } from "@octopusdeploy/design-system-components";
import { fontSize, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { useInlineStatusQuery } from "@octopusdeploy/octopus-react-client";
import type { KubernetesObjectStatus } from "@octopusdeploy/octopus-server-client";
import { OctopusError } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import React, { useMemo } from "react";
import { Skeleton } from "~/areas/projects/components/Projects/Skeleton";
import { SnapshotTaken } from "~/areas/tasks/components/Task/Kubernetes/SnapshotTaken";
import { CodeEditor } from "~/components/CodeEditor/CodeEditor";
type KubernetesObjectDrawerProps = {
    onClose: () => void;
    objectStatus: KubernetesObjectStatus | undefined;
};
const styles = {
    mainContainer: css({
        padding: space[16],
        paddingBottom: space[32],
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        position: "relative",
    }),
    contentHeading: css({
        marginBottom: space[16],
    }),
    heading: css({
        color: themeTokens.color.text.primary,
        font: text.regular.bold.large,
        marginBottom: space[4],
    }),
    codeEditorContainer: css({
        position: "relative",
        flexGrow: 1,
    }),
    loadingSkeletonContainer: css({
        position: "absolute",
        top: `calc(30px + 1rem + 2px + 4px)`, // toolbar height + toolbar padding + toolbar border + extra padding
        left: 0,
        width: "100%",
    }),
    loadingSkeletonItem: css({
        marginLeft: "50px",
        marginRight: space[8],
        height: `calc(${fontSize["medium"]} * 1.4)`, //same line height as the code lines
        display: "flex",
        alignItems: "center",
        "> div": {
            height: fontSize["medium"], //set the skeletons to be the same height as the text
        },
    }),
    bulletStyle: css({
        listStyleType: "disc",
    }),
};
const skeletonRowLengths = ["25rem", "15rem", "40rem", "30rem"];
export const KubernetesObjectDrawer = (props: KubernetesObjectDrawerProps) => {
    const { onClose, objectStatus } = props;
    const { result, isLoading, error } = useInlineStatusQuery(async (repo) => (objectStatus?.ManifestId ? repo.KubernetesManifest.getKubernetesManifest(objectStatus.ManifestId) : null), [objectStatus], "Kubernetes Object Manifest by Id");
    // the code editor control adds an extra row at the end, so do one less row in the manifest
    // this is there so that we get the row numbers in the editor for the skeleton rows to match up to
    const loadingManifest = useMemo(() => skeletonRowLengths.slice(0, skeletonRowLengths.length - 1).reduce((str) => `\n${str}`, ""), []);
    const errorCallout = useMemo(() => {
        if (!error) {
            return null;
        }
        if (OctopusError.isOctopusError(error)) {
            return (<Callout type={"danger"} title={error.ErrorMessage}>
                    <ul className={styles.bulletStyle}>
                        {error.Errors?.map((err) => (<li key={err}>{err}</li>))}
                    </ul>
                </Callout>);
        }
        else {
            return (<Callout type={"danger"} title="Error">
                    {error.message}
                </Callout>);
        }
    }, [error]);
    return (<Drawer variant={"customActions"} isOpen={!!objectStatus} onClose={() => onClose} customActions={[<ActionButton key={`Cancel ${objectStatus?.Name}`} type={ActionButtonType.Secondary} label="Close" accessibleName={`Close ${objectStatus?.Name}}`} onClick={onClose}/>]} title={objectStatus?.Name || ""} subtitle={objectStatus?.Kind}>
            {objectStatus && (<div className={styles.mainContainer}>
                    <div className={styles.contentHeading}>
                        <div className={styles.heading}>Object YAML snapshot</div>
                        <SnapshotTaken date={objectStatus.LastModified}/>
                    </div>
                    {errorCallout || (<div className={styles.codeEditorContainer}>
                            <CodeEditor language={"YAML"} value={result?.Resource?.ManifestContent || loadingManifest} onChange={noOp} allowFullScreen={true} showCopyButton={true} readOnly={true} showLineNumbers={true} settingsOverride={{ wordWrap: false }} fullHeight={true}/>
                            {isLoading && (<div className={styles.loadingSkeletonContainer}>
                                    {skeletonRowLengths.map((width, i) => (<div key={i} className={cx(styles.loadingSkeletonItem, css({ width }))}>
                                            <Skeleton shape={"Rounded"} borderRadius={"small"}/>
                                        </div>))}
                                </div>)}
                        </div>)}
                </div>)}
        </Drawer>);
};
